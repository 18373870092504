import React from 'react';
import globeIcon from '../assets/img/globe.png'; // Replace with your actual globe icon path
import mapImage from '../assets/img/map.png'; // Replace with your actual map image path
import networkIcon from '../assets/img/network.png'; // Replace with your actual network icon path
import '../styles/NetworkSection.css'; // Ensure this path is correct

const NetworkSection = () => {
  return (
    <div className='network-section'>
      <h2>Check out our Bounty Hunter Network</h2>
      <img src={mapImage} alt='World Map' className='map-image' />
      <div className='network-details'>
        <div className='network-info'>
          <img src={networkIcon} alt='Expansive Network' className='icon' />
          <h3>Expansive Network</h3>
          <p>Over 8000 skilled individuals engaging in diverse bounties.</p>
        </div>
        <div className='network-info'>
          <img src={globeIcon} alt='Global Network' className='icon' />
          <h3>Global Network</h3>
          <p>Spanning 20 countries across all 7 continents.</p>
        </div>
      </div>
    </div>
  );
};

export default NetworkSection;
