import React, { useEffect } from 'react';
import SignupBanner from '../components/SignupBanner'; // Import the BountyHunterBanner component
import Signupform from '../components/Signupform';
import { customScrollToTop } from '../helpers';
import { Footer } from '../components/Footer';
import '../styles/Signup.css'; // Ensure this path is correct


const SignUp = () => {
  useEffect(() => {
    return () => {
      customScrollToTop();
    };
  }, []);

  return (
    <div className='signup-page'>
      <SignupBanner />
      <Signupform />
      <Footer />
    </div>
  );
};

export default SignUp;
