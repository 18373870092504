import React from 'react';
import { FaFilePdf, FaLinkedin } from 'react-icons/fa';
import JonImage from '../assets/img/Jon.jpg';
import MorganImage from '../assets/img/Morgan.jpg';
import ShaunakImage from '../assets/img/Shaunak.jpg';
import VirgImage from '../assets/img/Virg.jpg';
import AllysonImage from '../assets/img/allyson.png';
import '../styles/Team.css'; // Ensure you have Team.css in the correct location

const TeamMember = ({ name, title, imageUrl, pdfUrl, linkedinUrl }) => (
  <div className='team-member'>
    <img src={imageUrl} alt={name} className='team-member-image' />
    <h3 className='team-member-name'>{name}</h3>
    <p className='team-member-title'>{title}</p>
    {pdfUrl && (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.open(`/info/${name.toLowerCase().replace(' ', '-')}`, '_blank', 'noopener,noreferrer');
        }}
        rel='noopener noreferrer'
      >
        <FaFilePdf className='icon-pdf' />
      </a>
    )}
    {linkedinUrl && (
      <a href={linkedinUrl} target='_blank' rel='noopener noreferrer'>
        <FaLinkedin className='icon-linkedin' />
      </a>
    )}
  </div>
);

const Team = () => {
  const teamMembers = [
    {
      name: 'Virginia Puccio',
      title: 'Founder, CEO',
      imageUrl: VirgImage,
      pdfUrl: 'www.google.com', // This can be removed if not needed
      linkedinUrl: 'https://www.linkedin.com/in/virginiapuccio/',
    },
    {
      name: 'Jon Nam',
      title: 'Co-founder, COO',
      imageUrl: JonImage,
      pdfUrl: 'www.google.com', // This can be removed if not needed
      linkedinUrl: 'https://www.linkedin.com/in/jon-nam/',
    },
    {
      name: 'Shaunak Chaudhary',
      title: 'Lead Software Engineer',
      imageUrl: ShaunakImage,
      pdfUrl: 'www.google.com', // This can be removed if not needed
      linkedinUrl: 'https://www.linkedin.com/in/shaunakc/',
    },
    {
      name: 'Morgan Purcell',
      title: 'Software Engineer',
      imageUrl: MorganImage,
      pdfUrl: 'www.google.com', // This can be removed if not needed
      linkedinUrl: 'https://www.linkedin.com/in/morganfpurcell/',
    },
    {
      name: 'Allyson Ferreira',
      title: 'Marketing Manager',
      imageUrl: AllysonImage,
      pdfUrl: 'www.google.com', // This can be removed if not needed
      linkedinUrl: 'https://www.linkedin.com/in/allyson-ferreira/',
    },
  ];

  return (
    <section className='team-section'>
      <h2 className='team-section-heading'>Meet Our Team</h2>
      <div className='team-members-container'>
        {teamMembers.slice(0, 2).map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
      <div className='team-members-container'>
        {teamMembers.slice(2).map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </section>
  );
};

export default Team;
