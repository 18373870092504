import { motion } from 'framer-motion';
import React from 'react';
import '../styles/MediaBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const scrollbelow = () => {
  const section = document.getElementById('media-section');
  section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const MediaBannerBanner = () => {
  return (
    <motion.div
      className='media-banner'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <div className='media-banner-content'>
        <h1>Explore Our Media Highlights</h1>
        <p>
        Delve into our recent accomplishments and events. From prestigious startup programs to insightful panels and podcasts, discover how Fuel AI is making strides in the AI industry.
        </p>
        <div className='media-banner-buttons'>
          <motion.button
            className='media-banner-button'
            onClick={scrollbelow}
            variants={buttonVariants}
            whileHover='hover'
          >
            Learn More
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default MediaBannerBanner;