import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import bountyImage from '../assets/img/getpaid.png'; // Correct path to your image
import { ArrowRightCircle } from 'react-bootstrap-icons';
import '../styles/BountyHunter.css'; // Ensure the CSS path is correct

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const BountyHunter = () => {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <div
      id='bounty-hunter-section'
      className='bounty-hunter-section text-white text-center py-5'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {/* Image on the left */}
          <div className='col-md-6'>
            <img src={bountyImage} alt='Abstract Shape' className='img-fluid' />
          </div>
          {/* Text on the right */}
          <div className='col-md-6 text-md-left'>
            <h2 className='paidtext'>Get Paid to Capture Data</h2>
            <ul className='benefits'>
              <li>Photos and videos become money.</li>
              <li>Select bounties, enjoy flexible earning.</li>
              <li>Contribute to cutting-edge AI projects.</li>
            </ul>

            <motion.button
              className='btn-bounty-hunters'
              onClick={() => navigate('/bounty-hunters')} // This will navigate to the BountyHunterPage
              variants={buttonVariants}
              whileHover='hover'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Bounty Hunters
              <ArrowRightCircle size={24} style={{ marginLeft: '8px' }} /> {/* Adjust size and margin as needed */}
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BountyHunter;
