import React from 'react';
import missionimage from '../assets/img/missionimage.png';
import valuesimage from '../assets/img/visionimage.png';

import '../styles/Mission1.css';

const Mission1 = () => {
  return (
    <div className="mission-vision-section">
      <div className="container">
        <div className="row mission-section">
          <div className="col-12 col-md-6 text-center d-flex flex-column justify-content-center mission">
            <img src={missionimage} alt="Mission Icon" />
            <h2 className="mission-title"><span>Our Mission</span></h2>
            <p>We are democratizing the global AI industry by offering regular, everyday people the opportunity to monetize their first-party AI training data and contribute to shaping the future of AI.</p>
          </div>
          <div className="col-12 col-md-6 text-center d-flex flex-column justify-content-center vision">
            <img src={valuesimage} alt="Vision Icon" />
            <h2 className="vision-title"><span>Core Values</span></h2>
            <p>
              Ethical AI Development<br />
              Empowerment Through Diversity
            </p>
            {/* <ul>
              <li>Ethical AI Development</li>
              <li>Empowerment Through Diversity</li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission1;
