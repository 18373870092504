import 'animate.css';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import '../styles/MainBanner.css';

const sentences = [
  'Welcome to Fuel AI',
  'Capture Data',
  'Get Paid',
];

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

export const MainBanner = () => {
  const [index, setIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const textRef = useRef(null);

  useEffect(() => {
    const currentSentence = sentences[index];
    let charIndex = 0;

    const typingInterval = setInterval(() => {
      setDisplayedText(currentSentence.slice(0, charIndex + 1));
      charIndex++;

      if (charIndex === currentSentence.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setDisplayedText('');
          setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        }, 2000); // Pause before typing the next sentence
      }
    }, 100); // Typing speed

    return () => clearInterval(typingInterval);
  }, [index]);

  const navigateToBountyHunter = () => {
    document
      .getElementById('bounty-hunter-section')
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <motion.div
      className='main-banner'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <div className='main-banner-content'>
        <h1 ref={textRef}>
          <span>{displayedText}</span>
          <span className="caret">|</span>
        </h1>
        <p>
        At Fuel AI, we connect users, known as Bounty Hunters, with AI companies, referred to as AI Builders, to create and utilize high-quality data. Bounty Hunters get paid to capture valuable data, while AI Builders can easily access first-party data from users to train their models and drive innovation.
        </p>
        <div className='main-banner-buttons'>
          <motion.button
            className='main-banner-button'
            onClick={navigateToBountyHunter}
            variants={buttonVariants}
            whileHover='hover'
          >
            Learn More
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default MainBanner;
