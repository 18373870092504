import React from 'react';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import dataImage from '../assets/img/Data.png'; // Correct path to your image
import '../styles/DataRequest.css'; // Ensure the CSS path is correct

const navigateDataCollect = () => {
  // Assuming your `BountyHunter` component is on the same page and you have set `id="data-receive-section"`
  document
    .getElementById('data-collect-section')
    .scrollIntoView({ behavior: 'smooth' });
};

const DataRequest = () => {
  return (
    <div
      id='data-request-section'
      className='data-request-section text-white text-center py-5'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {/* Image on the left */}
          <div className='col-md-6'>
            <img src={dataImage} alt='Abstract Shape' className='img-fluid' />
          </div>
          {/* Text on the right */}
          <div className='col-md-6 text-md-left'>
            <h2 className='paidtext'>Submit Your Data Request</h2>
            <p className='datarequesttext'>
              Send us your criteria and we'll tailor a collection strategy
              leveraging our Bounty Hunter network.
            </p>
            <button onClick={navigateDataCollect}>
              Learn More
              <ArrowDownCircle size={25} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRequest;
