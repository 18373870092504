import React from 'react';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import dataReceiveImage from '../assets/img/DataVault.png'; // Correct path to your image
import '../styles/DataReceive.css'; // Ensure the CSS path is correct

const navigatetoaicontact = () => {
  // Assuming your `BountyHunter` component is on the same page and you have set `id="aicontact-section"`
  document
    .getElementById('aicontact-section')
    .scrollIntoView({ behavior: 'smooth' });
};

const DataReceive = () => {
  return (
    <div
      id='data-receive-section'
      className='data-receive-section text-white text-center py-5'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {/* Image on the left */}
          <div className='col-md-6'>
            <img
              src={dataReceiveImage}
              alt='Abstract Shape'
              className='img-fluid'
            />
          </div>
          {/* Text on the right */}
          <div className='col-md-6 text-md-left'>
            <h2 className='paidtext'>Receive Your Data</h2>
            <p className='datareceivetext'>
              Access your customized dataset, curated exclusively for your AI's
              proprietary needs.
            </p>
            <button onClick={navigatetoaicontact}>
              Contact Us
              <ArrowDownCircle size={25} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataReceive;
