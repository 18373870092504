import React from 'react';
import { Footer } from '../components/Footer';
import MediaBanner from '../components/MediaBanner';
import MediaSection from '../components/MediaSection';
import '../styles/MediaPage.css';

const MediaPage = () => {
  return (
    <div className='media-page'>
      <MediaBanner />
      <MediaSection />

      <Footer/>
    </div>

  );
};

export default MediaPage;
