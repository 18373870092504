import React from 'react';
import { Container } from 'react-bootstrap';
import { Footer } from '../components/Footer';
import '../styles/Faq.css';

const Faq = () => {
  return (
    <Container fluid className='faq-container'>
      <header className='text-center'>
        {/* Placeholder for site navigation header */}
      </header>
      <main className='py-5 text-center text-light'>
        <h1 className='faq-title'>FAQs</h1>
        <section className='faq-text'>
          <div className="faq-item">
            <p>1. What happens after I sign up as a Bounty Hunter?</p>
            <div className="faq-answer">
            Review the welcome email and complete the ‘The New Money Company’ registration process. In the meantime before our app is live, you will be notified by email when new Bounties become available. No further action is required from you!
            </div>
          </div>

          <div className="faq-item">
            <p>2. How will Bounty Hunters get notifications?</p>
            <div className="faq-answer">
            Our new app is coming soon. When Bounty Missions become available in your area we will send out notifications via email. So please stay tuned!
            </div>
          </div>

          <div className="faq-item">
            <p>3. How old do I need to be to become a Bounty Hunter?</p>
            <div className="faq-answer">
            Bounty Hunters must be 18 years or older to comply with the legal age limit set by our partner, ‘The New Money Company,’ in accordance with their banking partners' requirements.
            </div>
          </div>

          <div className="faq-item">
            <p>4. What is ‘The New Money Company’ or ’NUMO’?</p>
            <div className="faq-answer">
            ‘The New Money Company’ is an identity verification platform that we have partnered with to ensure your safety. Everyone who signs up as a Bounty Hunter, must sign up through ‘The New Money Company’. ‘The New Money Company’ also serves as a payment option to Bounty Hunters worldwide.
            </div>
          </div>

          <div className="faq-item">
            <p>5. What if ‘The New Money Company’ or ‘NUMO’ is unavailable in my Country?</p>
            <div className="faq-answer">
            Upon registering as a Bounty Hunter, we have collaborated with 'The New Money Company' to include your country. You'll receive notifications as soon as it becomes available.
            </div>
          </div>

          <div className="faq-item">
            <p>6. How do I get a Bounty? When can I start hunting for data?</p>
            <div className="faq-answer">
            We are continuously expanding our Bounty opportunities. The availability will vary by Country and the demands of AI Builders. Rest assured, you'll receive an email once new Bounties are ready in your area.
            </div>
          </div>

          <div className="faq-item">
            <p>7. How much can I earn?</p>
            <div className="faq-answer">
              Our goal is to support our Bounty Hunters to earn a fair and exceptional hourly wage, depending on which country you are in. We base regional earnings on the World Bank’s Tier System. In Tier 1 countries, missions are constructed so that our Bounty Hunters can earn approximately $40USD per hour. In Tier 2 countries, the approximate hourly rate is $24USD per hour. And in Tier 3 countries, the approximate hourly rate is $7USD per hour. Earnings for videos, audio, text, and handwriting files vary by mission and will be announced in advance. The amount you can earn will always be transparent. 
              <br />
              If you are capturing data outside of the US refer to this link for which tier the country is in: <a href="https://www.consumerinterests.org/world-bank-tiers#:~:text=*%20Nations%20are%20grouped%20in%20tiers,reach%20the%20Tier%202%20level." target="_blank" rel="noopener noreferrer">World Bank Tiers</a>
            </div>
          </div>

          <div className="faq-item">
            <p>8. How much are the transaction fees?</p>
            <div className="faq-answer">
            The companies requesting the Bounties will agree upon the price per image or file. Fuel AI will deduct a small percentage (up to 20%). Credit card fees and other bank fees may also be deducted.
            </div>
          </div>

          <div className="faq-item">
            <p>9. How much time do I have to capture data?</p>
            <div className="faq-answer">
            The deadlines vary by mission. Sometimes it will be just a few days and other times it will be several weeks or months. The mission notification and instruction documents will describe the mission deadline.
            </div>
          </div>

          <div className="faq-item">
            <p>10. When do I get paid?</p>
            <div className="faq-answer">
              This will vary by mission and can depend on the mission deadline, length of the mission, and other factors. The time to receive payment will always be announced in advance and will be transparent.
            </div>
          </div>

          <div className="faq-item">
            <p>11. What happens to the data I capture?</p>
            <div className="faq-answer">
              It will be sold and transferred to the AI Builder requesting the Bounty. In most cases, your data will be used to train the next generation of artificial intelligence software. Other times, your data may be used for market research and analysis purposes.
            </div>
          </div>

          <div className="faq-item">
            <p>12. How do I upload the data I capture to submit it for payment?</p>
            <div className="faq-answer">
              In the meantime until our app, you must have access to Google Drive. Fuel AI will create a private folder for you, which is called a Data Vault. This data vault will be accessible only to you and to Fuel AI.
            </div>
          </div>

          <div className="faq-item">
            <p>13. Are there any other ways to earn money with Fuel AI?</p>
            <div className="faq-answer">
              Yes! Each Bounty Hunter will be issued a unique referral link. When you share this referral link, and your friends and family register using this link, you will be eligible to earn additional points and bonuses as they become active Bounty Hunters and capture data too.
            </div>
          </div>

          <div className="faq-item">
            <p>14. How do I know what AI my data will be used to train? How do I know my data won't end up in the wrong hands or be used to create something evil?</p>
            <div className="faq-answer">
              At Fuel AI, we are dedicated to keeping your data secure and private. Your data will not be exposed on the internet or be searchable. We only share your data with reputable companies that are developing AI solutions, and this is done on your behalf. One of our core values is Ethical AI Development, which means we are committed to supporting the creation of AI that benefits society. We carefully vet our customers to ensure they align with our mission of building AI for good.
            </div>
          </div>

          <div className="faq-item">
            <p>15. What if I still have questions that haven’t been addressed here?</p>
            <div className="faq-answer">
              You can email <a href="mailto:bountyhunters@gofuel.ai">bountyhunters@gofuel.ai</a>, and we’ll get back to you ASAP.
            </div>
          </div>

          <p>Copyright © 2024 Fuel.AI - GoFuel.AI</p>
        </section>
      </main>
      <Footer />
    </Container>
  );
};

export default Faq;
