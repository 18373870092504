import React from 'react';
import AboutUsBanner from '../components/AboutUsBanner'; // Import the BountyHunterBanner component
import { Footer } from '../components/Footer';
import Mission1 from '../components/Mission1'; // Import Mission component

import Team from '../components/Team';
import '../styles/AboutUsPage.css'; // Ensure this path is correct

const AboutUsPage = () => {
  return (
    <div className='about-us-page'>
      <AboutUsBanner /> {/* Include the BountyHunterBanner here */}
      <Mission1 />
      <Team></Team>
      <Footer></Footer>
    </div>
  );
};

export default AboutUsPage;
