import React from 'react';
import { Container } from 'react-bootstrap';
import { Footer } from '../components/Footer';
import '../styles/Subject.css';

const Subject = () => {
  return (
    <Container fluid className='subject-container'>
      <header className='text-center'>
        {/* Placeholder for site navigation header */}
      </header>
      <main className='py-5 text-center text-light'>
        <h1 className='subject-title'>Subject Release</h1>
        <p className='subject-intro'>
          Please carefully read our Subject Release.
        </p>
        <section className='subject-text'>
          <p>
            For good and valuable consideration, the receipt and sufficiency of
            which I hereby acknowledge, and by signing this release (“Release”),
            I the undersigned (“I” or “me”) hereby consent and grant permission
            to [Fuel AI] and its affiliates, successors and assigns
            (collectively, the “Collecting Party”) to take and record images,
            videos, and sound recordings of me in any format, and I agree that
            such images, videos, and sound recordings and any other images,
            videos, or sound recordings that I provide to Collecting Party will
            be deemed “Recordings” for purposes of this Release.
          </p>
          <p>
            Collecting Party shall be the exclusive owner of all rights,
            including copyright, in all Recordings. I hereby irrevocably
            transfer, assign, and otherwise convey to Collecting Party all
            right, title, and interest in and to the Recordings and all
            copyrights and other intellectual property rights in the Recordings
            arising in any jurisdiction throughout the universe in perpetuity,
            including all registration, renewal, and reversion rights, and the
            right to sue to enforce such rights against infringers. Without
            limiting the foregoing, I give further permission to Collecting
            Party to sell, license, reproduce, create derivative works of,
            distribute, publish, make promotional use of, and otherwise use and
            commercially exploit the Recordings for any purpose (including to
            train and improve artificial intelligence models and algorithms), in
            all forms of media now known or hereafter devised (specifically
            including, without limitation, television, film, print media, video,
            and Internet), worldwide and in perpetuity, in any manner deemed
            proper by Collecting Party. I acknowledge and agree that I have no
            right to review or approve Recordings that are created by or on
            behalf of Collecting Party, that the Recordings may be combined with
            other images, text and graphics, cropped, or otherwise combined,
            altered or modified in Collecting Party’s sole discretion, and that
            Collecting Party has no liability to me for any editing, alteration,
            or use of the Recordings or for any results or consequences thereof.
            Any credit or other acknowledgment of me with respect to the
            foregoing, if any, shall be determined by Collecting Party in
            Collecting Party 's sole discretion. I acknowledge and agree that I
            have no further right to additional consideration of any kind or
            nature with respect to the Recordings, and that Collecting Party has
            no obligation to create or use the Recordings or to exercise any
            rights given by this Release.
          </p>

          <p>
            To the fullest extent permitted by applicable law, I hereby
            irrevocably waive all legal and equitable rights relating to all
            liabilities, claims, demands, actions, suits, damages, and expenses,
            including but not limited to claims for copyright or trademark
            infringement, violation of moral rights, libel, defamation, invasion
            of any rights of privacy (including intrusion, false light, public
            disclosure of private facts, and misappropriation of name or
            likeness), violation of rights of publicity, physical or emotional
            injury or distress, or any similar claim or cause of action in tort,
            contract, or any other legal theory, now known or hereafter known in
            any jurisdiction throughout the world (collectively, "Claims"),
            arising directly or indirectly from the Collecting Parties' exercise
            of their rights under this Release or the production, exhibition,
            exploitation, advertising, promotion, or other use of the
            Recordings, and whether resulting in whole or in part from the
            negligence of Collecting Party or any other person, and I hereby
            covenant not to make or bring any such Claim against any Collecting
            Parties and forever release and discharge the Collecting Parties
            from all liability in connection with such Claims. I hereby
            expressly waive all rights and benefits under Section 1542 of the
            California Civil Code, and any similar statute, code, law or
            regulation of any other jurisdiction, to the fullest extent that
            applicable law permits me to waive such rights and benefits. I
            acknowledge that I have read and understand Section 1542 of the
            California Civil Code, which provides that “[a] general release does
            not extend to claims that the creditor or releasing party does not
            know or suspect to exist in his or her favor at the time of
            executing the release and that, if known by him or her, would have
            materially affected his or her settlement with the debtor or
            released party.”
          </p>

          <p>
            I represent and warrant to Collecting Party that (i) I am at least
            18 years of age, (ii) any Recording I provide to Collecting Party is
            a true likeness of me and not generated or enhanced by artificial
            intelligence or otherwise digitally altered (e.g., using filters,
            masks, color corrections, image combiners, or other digital editing
            tools), and (iii) I have full right, power, and authority to enter
            into this Release and grant the rights granted hereunder. I further
            represent and warrant to Collecting Party that I will provide only
            true and correct statements and other information in connection with
            the Recordings, and the Collecting Parties’ use of the Recordings
            and exercise of the rights and license granted hereunder do not, and
            will not, violate any right (including without limitation copyright,
            trademark, trade secret, right to privacy, or right of publicity)
            of, or conflict with or violate any contract with or commitment made
            to, any person or entity, and that no consent or authorization from,
            or any payment to, any third party is required in connection
            therewith. I agree to defend, indemnify, and hold harmless the
            Collecting Parties’ from and against all claims by third parties
            resulting from my breach or alleged breach of this Release or any of
            the foregoing representations and warranties.
          </p>

          <p>
            I acknowledge and agree that this Release is perpetual and
            irrevocable and binding upon my heirs and assigns. This Release is
            and will be governed by the laws of the state of California,
            excluding its rules regarding conflict of laws to the extent they
            would result in the application of another state’s laws.
          </p>

          <p>
          Copyright © 2024 Fuel.Ai - GoFuel.Ai
          </p>
        </section>
      </main>
      <Footer/>
    </Container>
  );
};

export default Subject;
