import { motion } from 'framer-motion';
import React from 'react';
import '../styles/AIBuilderBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const AIBuilderBanner = () => {
  const scrollToContact = () => {
    const section = document.getElementById('aicontact-section');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollbelow = () => {
    const section = document.getElementById('data-request-section');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <motion.div
      className='ai-builder-banner'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <div className='ai-builder-banner-content'>
        <h1>Fuel Your AI with Precision Data</h1>
        <p>
          Discover a streamlined path to high-quality data sourced by dedicated
          Bounty Hunters. Transform your AI and automation solutions with data
          that scales with your ambition.
        </p>
        <div className='ai-builder-banner-buttons'>
          <motion.button
            className='ai-builder-banner-button'
            onClick={scrollToContact}
            variants={buttonVariants}
            whileHover='hover'
          >
            Contact Us
          </motion.button>
          <motion.button
            className='ai-builder-banner-button'
            onClick={scrollbelow}
            variants={buttonVariants}
            whileHover='hover'
          >
            Learn More
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default AIBuilderBanner;
