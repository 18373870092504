import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../styles/TestimonialSection.css';

const testimonials = [
  {
    name: 'Mena Y',
    testimonial: '"As a Bounty Hunter for Fuel AI, it has been an interesting experience..."',
  },
  {
    name: 'Jeff E',
    testimonial: '"Very cool watching Fuel AI grow so fast! Awesome company and a lot of fun!"',
  },
  {
    name: 'Melissa L.',
    testimonial: '"Working with Fuel AI was easy, interesting and a lot of fun!"',
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialSection = () => {
  return (
    <div className='testimonial-section'>
      <h2>What Bounty Hunters say about us</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className='testimonial-card'>
            <div className='testimonial-text'>
              <p>{testimonial.testimonial}</p>
              <h5>{testimonial.name}</h5>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSection;