import React from 'react';
import { Footer } from '../components/Footer';
import WaitlistBanner from '../components/WaitlistBanner';
import '../styles/Waitlist.css';

const WaitlistPage = () => {
  return (
    <div className='waitlist-page'>
      <WaitlistBanner />
      <Footer></Footer>
    </div>
  );
};

export default WaitlistPage;
