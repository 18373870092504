import React from 'react';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import datacollectImage from '../assets/img/DataCollect.png'; // Ensure this path is correct
import '../styles/DataCollect.css'; // Make sure the path to your CSS file is correct

const navigateDataReceive = () => {
  // Assuming your `BountyHunter` component is on the same page and you have set `id="data-receive-section"`
  document
    .getElementById('data-receive-section')
    .scrollIntoView({ behavior: 'smooth' });
};

const DataCollect = () => {
  return (
    <div
      id='data-collect-section'
      className='data-collect-section text-white text-center py-5'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {/* Text on the left */}
          <div className='col-md-6 text-md-left'>
            <h2 className='aitext'>Our Network Collects the Data</h2>
            <p className='datacollecttext'>
              Access your customized dataset, curated exclusively for your AI's
              proprietary needs.
            </p>
            <button onClick={navigateDataReceive}>
              Learn More
              <ArrowDownCircle size={25} />
            </button>
          </div>
          {/* Image on the right */}
          <div className='col-md-6'>
            <img
              src={datacollectImage}
              alt='Abstract Shape'
              className='img-fluid'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCollect;
