import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import bountyImage from '../assets/img/aidata.png'; // Ensure this path is correct
import '../styles/AIBuilders.css'; // Make sure the path to your CSS file is correct

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const AIBuilders = () => {
  const navigate = useNavigate(); // Hook for navigation
  return (
    <div
      id='ai-builder-section'
      className='ai-builder-section text-white text-center py-5'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {/* Text on the left */}
          <div className='col-md-6 text-md-left'>
            <h2 className='aitext'>Empower Your AI with Real-World Data</h2>
            <ul className='benefits'>
              <li>Access a diverse global data network.</li>
              <li>High-quality, ready-to-use datasets.</li>
              <li>Custom data for unique AI needs.</li>
            </ul>

            <motion.button
        className='btn-ai-builders'
        onClick={() => navigate('/ai-builders')}
        variants={buttonVariants}
        whileHover='hover'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        AI Builders
        <ArrowRightCircle size={24} style={{ marginLeft: '8px' }} />
      </motion.button>
          </div>
          {/* Image on the right */}
          <div className='col-md-6'>
            <img src={bountyImage} alt='Abstract Shape' className='img-fluid' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIBuilders;
