import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import JonImage from '../assets/img/Jon.jpg';
import MorganImage from '../assets/img/Morgan.jpg';
import ShaunakImage from '../assets/img/Shaunak.jpg';
import VirgImage from '../assets/img/Virg.jpg';
import AllysonImage from '../assets/img/allyson.png';
import { Footer } from '../components/Footer';
import '../styles/Info.css';

const teamMembers = [
  {
    name: 'virginia-puccio',
    displayName: 'Virginia Puccio',
    title: 'Founder, CEO',
    imageUrl: VirgImage,
    description: [
      "Virginia Puccio, Founder and CEO of Fuel AI, leads the premier global marketplace connecting people who take photos with their smartphones to AI Builders for first- party data. With a versatile professional background spanning roles at Google, RetailNext, Standard AI, and the Jewish Community Center of Silicon Valley, she brings a wealth of experience in large corporations, startups, non-profits, and entrepreneurship. Virginia's executive acumen is complemented by her contributions as a rugby coach at both University and Club levels",
      "In her previous role as Head of Data Operations at Standard AI, Virginia orchestrated a global team to pioneer data development for autonomous checkout technology, propelling the company to Unicorn status with a billion-dollar valuation in early 2020.",
      "Before launching Fuel AI, Virginia played a pivotal role as the leader of Growth & Strategic Partnerships at DataPure, a business process outsourcing company. Here, she refined her expertise in generating data for AI and automation across a variety of use cases, laying the foundation for her innovative work at Fuel AI.",
      "Beyond her corporate success, Virginia is dedicated to making a difference. Actively volunteering, she serves on the Dean's Advisory Board at Santa Clara University, mentors in the Conexiones Mentorship Program at the Hispanic Foundation of Silicon Valley, and chairs the Annual Charity Golf Tournament for her former rugby club, The San Jose Seahawks Rugby Football Club",
      "A proud alumna of the University of California, Davis, with a BA degree and an MBA from Santa Clara University, Virginia seamlessly transitioned from a Division 1 collegiate rugby athlete to community service. Retired from rugby after nearly two decades of experience in the sport, she now manages women’s and co-ed soccer teams creating a supportive environment where women can both enjoy and learn the game of soccer, fostering skill development that seamlessly translates to success in the professional arena.",
      "Outside the realm of AI and business, Virginia finds joy in hiking, soccer, yoga, meditation, and cherishing moments with her dog and two cats. Her multifaceted career and commitment to community engagement define her as a visionary leader in the ever-evolving landscape of AI and technology."
    ],
  },
  {
    name: 'jon-nam',
    displayName: 'Jon Nam',
    title: 'Co-founder, COO',
    imageUrl: JonImage,
    description: [
      "Jon Nam, the Co-founder and COO of Fuel AI, brings over 25 years of remarkable success as a results-oriented Senior Operations and IT Executive in diverse industries, including retail, innovation technology, broadcasting, and manufacturing. Renowned for his strategic acumen, Jon excels in guiding businesses to achieve operational excellence, process innovation, and surpass P&L targets.",
      "Jon is a sought-after advisor, providing expertise in scaling, process optimization, quality assurance, KPI development, client success engagement, system integration, and addressing production bottleneck issues. His extensive skill set encompasses IT solutions architecture, global operations, supply chain management, logistics, vendor management, program management, business development, demand planning, data centers, and system optimization.",
      "In his illustrious career, Jon has held key leadership roles, including Senior Vice President of Global Operations at Mashgin, Vice President of Global Services at Standard AI, Director of Operations at CCIntegration, Vice President of Operations at RetailNext, and Director of Global IT and Media Technology at Macy’s Merchandising Group.",
      "As Senior Vice President at Mashgin, Jon played a pivotal role in driving planning and strategy, incorporating data-driven insights for actionable KPIs, establishing organizational structures for hyper revenue growth, and fostering a startup culture desired by all employees. Jon's expertise has consistently contributed to achieving unicorn status and triple-digit revenue growth at both Standard AI and Mashgin.",
      "Jon holds a BS in Information Technology and an MBA in Business Administration from American InterContinental University. Beyond the world of AI, Jon enjoys playing tennis with his son, cheering for his daughter in dance competitions, and indulging in barbecue cooking with his wife. His multifaceted expertise and commitment to excellence position Jon as a seasoned leader in the ever-evolving landscape of operations and technology.",
    ],
  },
  {
    name: 'shaunak-chaudhary',
    displayName: 'Shaunak Chaudhary',
    title: 'Lead Software Engineer',
    imageUrl: ShaunakImage,
    description: [
      "Shaunak Chaudhary is the Engineering Lead of Fuel AI, the preeminent global marketplace connecting smartphone photographers with AI Builders for first-party data. Shaunak is also the former Co-founder and CEO of Tiffeasts. This innovative venture, facilitated home-cooked meal delivery by empowering individuals who preferred to work from home, such as moms and grandparents. Operating in Pune and Mumbai, India, Tiffeasts flourished for two years, navigating the challenges of the pandemic, and achieving milestones such as 4,000 website visitors, 1400+ app downloads, and over 850+ successful meal deliveries. Despite its success, Shaunak's relocation to the US has made it impractical for him to effectively dedicate time to Tiffeasts.",
      "As a seasoned engineer, Shaunak thrives on the product side of technology, reveling in the entire product lifecycle from ideation to development. The blend of analytical thinking, creative marketing, and value optimization fuels his passion, presenting constant challenges for innovation and outside-the-box thinking.",
      "Currently pursuing a Master's in Computer Science & Engineering at Santa Clara University, Shaunak will graduate in June 2024. His academic journey began with a Bachelor of Engineering from Pune University in India in March 2020. Actively involved as a Research Assistant in the Paper Prison Project, Shaunak delves into criminal datasets to identify gaps in criminal record expungement, aligning with the Clean Slate Initiative's mission for societal change.",
      "With a rich athletic background as a national-level gymnast for half his life, Shaunak remains passionate about sports and outdoor adventures. Beyond his professional and academic pursuits, he engages in badminton, hiking, and trekking expeditions, considering these activities not just as hobbies but as a way to deeply connect with nature. Shaunak's diverse experiences and commitment to impactful research exemplify his holistic approach to life and work."
    ],
  },
  {
    name: 'morgan-purcell',
    displayName: 'Morgan Purcell',
    title: 'Software Engineer',
    imageUrl: MorganImage,
    description: [
      "Morgan Purcell is a dynamic and innovative Full-Stack Engineer at Fuel AI, where he leverages cutting-edge technologies to create user-centric experiences. His work drives AI innovation and redefines the dynamics of data exchange.",
      "Born and raised in Ireland, Morgan brings a unique international perspective to his role. He has a distinguished background in sports, having represented the Leinster and Connacht underage rugby teams in Ireland, and earned a cap for the underage Ireland rugby team, honing his teamwork and leadership skills in the process. Awarded an academic and athletic scholarship to a top U.S. rugby school, Morgan excelled both on the field and in his academic pursuits. Recognized as a Student Life Sports Academic High Performer in 2022, Morgan was also a consistent member of the Dean's Honor Roll at Lindenwood University.",
      "Completing his Bachelor’s degree in Computer Science at Lindenwood University as a student athlete, Morgan graduated Magna Cum Laude in May 2023. While in college, he gained valuable industry experience as a Front-End Engineer for P3 Development Group, leading the development of the company’s website. He also served as a Back-End Engineer at Lindenwood University Athletics Performance Department, playing a pivotal role in creating a scalable athlete management system to track and visualize performance metrics for a large number of student- athletes. Morgan's approach to problem-solving in software engineering and user experience design is characterized by creativity and innovation.",
      "Beyond his professional endeavors, Morgan enjoys playing rugby with his team in San Jose, working out, exploring music, traveling, and engaging in personal software engineering projects, including the development of AI chat assistants and apps. Morgan’s multifaceted background, academic achievements, and commitment to innovation make him a standout figure in the realm of AI and software engineering."
    ],
  },
  {
    name: 'allyson-ferreira',
    displayName: 'Allyson Ferreira',
    title: 'Marketing Manager',
    imageUrl: AllysonImage,
    description: [
      "Allyson Ferreira, currently serving as the Marketing Manager for Fuel AI, boasts a diverse background blending professional experience, educational accomplishments, and a rich tapestry of personal interests. Her journey through various roles exemplifies her dynamic approach to challenges and her commitment to excellence.",
      "With a Bachelor of Arts degree in Communication and a Bachelor of Science degree in Psychology, Allyson graduated in 2022, concurrently excelling as a student-athlete at Santa Clara University for five years and earning prestigious accolades like the 2x All-West Coast Conference (WCC) award. Her academic pursuits continue as she pursues an MBA, slated for completion in August 2024.",
      "Allyson's professional trajectory is marked by significant achievements. From doubling personal sales within a month as a Sales Associate at Orangetheory Fitness, to orchestrating a strategic marketing plan resulting in a 40% surge in Men’s basketball fan attendance during her tenure as a Marketing and Operations intern at Santa Clara University, she consistently demonstrates her ability to drive impactful outcomes.",
      "Beyond her career, Allyson finds fulfillment in a diverse array of activities. Whether she's hiking, surfing, traveling, or engaging in hot yoga, her zest for life is palpable. Her commitment to giving back is evident through her volunteer work as an Assistant Softball Coach at Menlo-Atherton High School and her involvement with HERicanes, a non-profit organization dedicated to coaching and teaching softball to young players.",
      "What truly propels Allyson is her intrinsic drive to excel and her desire for personal growth. She thrives on challenges, constantly pushing herself to new heights. Whether it's achieving sales targets, driving marketing strategies, or pursuing athletic endeavors, Allyson's competitive spirit and passion shine through, inspiring those around her to reach for their own aspirations."
    ],
  },
];

const Info = () => {
  const { memberName } = useParams();
  const member = teamMembers.find(m => m.name === memberName);

  if (!member) {
    return <div>Member not found</div>;
  }

  return (
    <Container fluid className='info-container'>
      <main className='py-5 text-center text-light'>
      <img src={member.imageUrl} alt={member.displayName} className='team-member-image' />
        <h1 className='info-title'>{member.displayName}</h1>
        <p className='info-intro'>{member.title}</p>
        <section className='info-text'>
          {member.description.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </section>
      </main>
      <Footer/>
    </Container>
  );
};

export default Info;
