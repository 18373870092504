import React from 'react';
import bronco from '../assets/img/bronco.png';
import eclerx from '../assets/img/eclerx.png';
import googlImage from '../assets/img/google.png';
import NvdiaImage from '../assets/img/nvidia.png';
import scu from '../assets/img/scu.png';
import sjsu from '../assets/img/sjsu.png';
import '../styles/MediaSection.css'; // Ensure this path is correct based on your project structure

const MediaSection = () => {
  const pressReleases = [
    {
      title: 'Nvidia Inception Program for Startups',
      subtitle: 'Fuel AI is honored to have been selected for the prestigious NVIDIA Inception Program for Startups. This program provides us with access to invaluable resources, expert guidance, and strategic support from NVIDIA, enabling us to accelerate our innovation and growth in the AI industry.',
      date: 'January 2024',
      image: NvdiaImage // Make sure these are valid URLs
    },
    {
      title: 'Google for Startups Cloud Program',
      subtitle: "Fuel AI is proud to be part of the Google Cloud Startup Program. This initiative offers us unparalleled access to Google Cloud's advanced technologies, mentorship, and technical support, empowering us to scale our operations and drive innovation in the AI data marketplace.",
      date: 'March 2024',
      image: googlImage
    },
    {
      title: 'SJSU Veterans Panel',
      subtitle: 'Fuel AI is excited to announce that our CEO was invited to speak at the SJSU Veterans Panel at San Jose State University. This event provided a valuable platform to discuss the latest advancements in AI and share insights with the academic community and industry leaders.',
      date: 'May, 2024',
      image: sjsu
    },
    {
      title: 'Women in AI',
      subtitle: 'Our CEO was invited as a chief guest for the "Women in AI: Breaking Barriers and Shaping the Future" panel at SCU. This event, organized by the SCU Graduate Women in Business, provided a platform to discuss the critical role of women in the AI industry and inspire the next generation of female leaders.',
      date: 'April 2024',
      image: scu
    },
    {
      title: 'Bronco Ventures Discussion Panel',
      subtitle: 'Our CEO was invited as a chief guest on the Bronco Ventures Discussion Panel at the Bronco Venture Accelerator, SCU. This event provided a dynamic platform to discuss innovation, entrepreneurship, and the future of AI, fostering collaboration and growth within the startup ecosystem.',
      date: 'May 2024',
      image: bronco
    },
    {
      title: 'EClerx Podcast',
      subtitle: 'Fuel AI is thrilled to announce that our CEO was invited to the eClerx Podcast, a platform by the global leader in business process management and analytics, to discuss the critical importance of first-party data in AI.',
      date: 'March 2024',
      image: eclerx
    },
  ];

  return (
    <div id="media-section" className="media-container">
      <h1 className="media-text-center">Browse all news & press releases</h1>
      {pressReleases.map((press, index) => (
        <div className="media-card" key={index}>
          <div
            className="media-card-img-top"
            style={{ backgroundImage: `url(${press.image})` }}
          ></div>
          <div className="media-card-body">
            <h5 className="media-card-title">{press.title}</h5>
            <p className="media-card-text">{press.subtitle}</p>
            <p className="media-text-muted">{press.date}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MediaSection;
