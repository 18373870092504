import React from 'react';
import { Footer } from '../components/Footer';
import '../styles/Terms.css';

const Terms = () => {
  return (
    <div fluid className='terms-container'>
      <header className='text-center'>
        {/* Placeholder for site navigation header */}
      </header>
      <main className='py-5 text-center text-light'>
        <h1 className='terms-title'>Terms of Use</h1>
        <p className='terms-intro'>
          Please carefully read all of the following terms and conditions of
          these Terms of Service ("Terms").
        </p>
        <section className='terms-text'>
          <p>
            These Terms create a binding legal agreement between you and
            Fuel.AI, Inc. (“Fuel AI”) and govern your use of (i) the Fuel AI
            website (the “Site”); (ii) the Fuel AI mobile application (the
            “App”); and (iii) the content and functionality provided via the
            Site and App (such content and functionality, together with the Site
            and App, collectively, the “Services”). The “Services” will include
            any new features, services, tools, or other resources (“New
            Features”) that Fuel AI may, in Fuel AI’s sole discretion, make
            available to you from time to time; for the avoidance of doubt,
            these Terms will govern your use of any such New Features.
          </p>
          <p>
            PLEASE CAREFULLY REVIEW THESE TERMS AND THE FUEL AI PRIVACY POLICY
            (THE “PRIVACY POLICY") BEFORE USING THE SERVICES. BY USING THE
            SERVICES (INCLUDING BY VISITING THE SITE, ACCESSING OR USING THE
            APP, OR MAKING ANY OTHER USE OF THE SERVICES), YOU ACCEPT AND AGREE
            TO BE BOUND BY THESE TERMS AND ACKNOWLEDGE THAT YOU HAVE REVIEWED
            THE PRIVACY POLICY. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, OR IF
            YOU OBJECT TO THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE
            SERVICES. PLEASE NOTE THAT SECTION 15 OF THESE TERMS CONTAIN A
            BINDING ARBITRATION CLAUSE AND A CLASS ACTION WAIVER THAT AFFECTS
            YOUR RIGHTS FOR RESOLVING DISPUTES WITH FUEL AI.
          </p>
          <p>
            The Services are offered and available only to users who are at
            least eighteen (18) years of age. By using the Services, you affirm,
            represent, and warrant that you (i) are at least eighteen (18) years
            of age, (ii) are fully able and competent to enter into the terms,
            conditions, obligations, affirmations, representations, and
            warranties set forth in these Terms, and to abide by and comply with
            these Terms, and (iii) have not previously been terminated, removed,
            or suspended from the Services. If you are using the Services on
            behalf of a company, organization, or other entity, you further
            affirm, represent, and warrant that you have authority to bind that
            organization to these Terms, and you agree to be bound by these
            Terms on behalf of that organization. For the avoidance of doubt, if
            you are using the Services on behalf of a company, organization, or
            other entity, the terms “you” and “your” will include such company,
            organization, or other entity.
          </p>

          <div className='terms-section'>
            <h2 className='terms-heading'>1. Ownership.</h2>
            <p>
              The Services are owned and operated by Fuel AI. You acknowledge
              and agree that Fuel AI and its licensors own all right, title, and
              interest in and to the Services, including: (i) all information,
              data, software, text, tools, analyses, displays and visual
              interfaces, graphics, images, video, and audio, and all other
              elements of the Services, and the design, selection, arrangement,
              and “look and feel” thereof; and (ii) all worldwide intellectual
              property and other legal rights (including, but not limited to, in
              any and all copyrights, patents, patent applications, trade
              secrets, trademarks and other intangible rights) therein. You may
              not publish, reproduce, distribute, display, perform, edit, adapt,
              modify, or otherwise exploit any part of the Services without Fuel
              AI’s written consent. You will not earn or acquire any ownership
              rights in any copyrights, patents, trade secrets, trademarks or
              other intellectual property rights anywhere in the world on
              account of these Terms or any access to or use of the Services.
              You agree and understand that Fuel AI is not obligated to provide
              you with access to the Services. You agree that all content
              provided to you through the Services is owned by Fuel AI and/or
              other third parties and that you will have no ownership rights in
              such content.
            </p>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>
              2. Posting or Performing Bounties.
            </h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                By creating an account to use the Services, you are signing up
                to complete tasks, projects or bounties for third-party Fuel AI
                clients ("AI Builders") and perform support services related to
                such tasks, projects or bounties (collectively, the "Bounties"),
                or, if you are an AI Builder, you are signing up to post and
                make Bounties available on the Services.
              </li>

              <li>
                In order to post or perform certain Bounties, you may need to
                (i) download Fuel AI software, (ii) use AI Builders’ websites,
                mobile applications, platforms, or devices, or (iii) use other
                platforms to communicate about the Services or Bounties
                (collectively, “Bounty Systems”).
              </li>

              <li>
                You agree to perform the Bounties and deliver any other data,
                content, or materials you provide to Fuel AI or AI Builders
                accurately, promptly, consistent with any scope of work or other
                specifications provided by Fuel AI or the relevant AI Builders,
                and in full compliance with all applicable laws and regulations.
                You agree to (i) complete all Bounties by yourself to the best
                of your ability without seeking the assistance of other persons
                or technological automation such as bots, scripts, AI or any
                other tools, (ii) only work on Bounties for which you have fully
                read and understood the instructions and accompanying sample
                images, and (iii) complete Bounties in a diligent, timely, and
                honest manner. Fuel AI reserves the right, but has no
                obligation, to confirm the accuracy and compliance with these
                Terms of the Bounties and any associated deliverables.
              </li>

              <li>
                In connection with performing the Bounties, you must obtain a
                valid Subject Release in the form made available by Fuel AI (a
                copy of which you may request from FuelAI by contacting FuelAI
                directly at contact@gofuel.ai) from each data subject that is
                featured in any materials, data and content that you submit via
                the Services (“Submitted Content”) and submit such Subject
                Releases to Fuel AI.
              </li>

              <li>
                You are responsible for determining which Bounties you choose to
                perform and participate in, and when, where, and how you do so.
                The frequency of your use of the Services, Bounties performed,
                and (except as provided in Section 2(c) above) your method of
                completing specified Bounties remain at all times at your
                discretion. You are not required to use the Services or perform
                Bounties at any specified time or day.
              </li>

              <li>
                You are responsible for providing all necessary tools or
                equipment that you need to perform the Bounties. You are
                responsible for obtaining the data network access necessary to
                use the Bounty Systems. Your mobile network’s data and messaging
                rates and fees may apply if you access or use the Bounty Systems
                from your mobile device. Fuel AI does not guarantee that the
                Bounty Systems, or any portion thereof, will function on any
                particular hardware or devices. In addition, the Bounty Systems
                may be subject to malfunctions and delays inherent in the use of
                the Internet and electronic communications. Fuel AI will not
                reimburse you for any expenses incurred in performing your
                selected Bounties.
              </li>

              <li style={{ marginBottom: '20px' }}>
                Following completion of the Bounties you have selected, subject
                to verification that such Bounties have been accurately and
                otherwise adequately completed, you will accrue earnings in your
                account in the amount specified by the AI Builder in the Bounty
                after receiving such amount from the AI Builder and deducting
                Fuel AI’s fees as described on the Service. If the balance in
                your account exceeds $30, Fuel AI will transmit payment to you
                of your full account balance at the end of the month. If the
                balance does not meet the $30 threshold, you will not be paid
                that month and your balance will be rolled over to the following
                month. Subject to applicable law, Fuel AI reserves the right to
                delay or suspend payment during any investigation into your
                compliance with these Terms. A violation of these Terms, in Fuel
                AI’s sole determination, may result in non-payment to you of
                some or all amounts accrued, as well as suspension or
                deactivation of your account. It is your responsibility to
                ensure that you have a payment method approved by Fuel AI to
                receive payments in US Dollars or the specified currency. You
                are responsible for any and all taxes that may apply to your
                receipt of such payments, and Fuel AI may deduct and withhold
                such taxes from its payments to you if Fuel AI determines that
                it is required to do so by applicable law.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>3. Referral Program.</h2>
            <p>
              You may, subject to eligibility and other criteria, be issued
              points for volumes of data captured in accordance with these Terms
              and the Bounty Instructions, referring the Services to third
              parties that participate as active Bounty Hunters in the
              performance of a Bounty, and other activities as determined by
              Fuel AI in its sole discretion. Fuel AI reserves the right, at its
              sole discretion, to prohibit you from engaging in the referral
              program if Fuel AI deems or suspects that you have engaged in or
              has attempted to engage in any of the following: (i) acting in
              violation of these Terms; (ii) damaging, tampering with or
              corrupting the operation of the Services; or (iii) activity deemed
              in the sole discretion of Fuel AI to be generally inconsistent
              with the intended operation of the referral program. Fuel AI
              reserves the right to cancel or suspend the referral program in
              its sole discretion.
            </p>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>4. Usage Rights and Restrictions.</h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                Subject to your compliance with these Terms, Fuel AI hereby
                grants to you a limited, personal, non-exclusive,
                non-transferable, non-sublicensable and revocable right to
                access and use the Services in accordance with these Terms and
                solely in connection with posting or performing Bounties, as
                applicable (the “Permitted Purposes”). By downloading or using
                the Services, you acknowledge that: (i) the Services are not
                sold to you; and (ii) third-party terms and fees, such as those
                from a mobile carrier, may apply to the use and operation of any
                device in connection with your use of the Services, and that you
                are solely responsible for any such third-party terms and fees.
              </li>

              <li>
                Accounts registered by “bots” or other automated methods or
                means are not permitted. You may not maintain more than one
                account, and you may not sell or purchase accounts. You must
                provide your name, date of birth, location, a valid email
                address, and any other information requested by or on behalf of
                Fuel AI in connection with the account registration process. You
                agree to maintain accurate, complete, and up-to-date account
                information. You agree not to use a virtual private network or
                other technology to mislead Fuel AI about your location or
                identity.
              </li>

              <li>
                You may not use the Services for any purpose other than the
                Permitted Purposes nor may you transfer or otherwise permit any
                other person or entity to access the Services using your
                username and password, which you acknowledge and agree are
                personal to you and are non-transferable. You are responsible
                for taking reasonable steps to maintain the confidentiality of
                your username and password, and you are responsible for all
                activities under your account (including the posting of any
                Submitted Content under your account). You agree to promptly
                notify Fuel AI of any unauthorized use of your username,
                password, or other account information, or of any other actual
                or reasonably suspected breach of security that you become aware
                of involving your account or the Services. Fuel AI will not be
                liable to you for any loss you may incur as a result of someone
                else using your username, password or account with or without
                your knowledge. You may be held liable for losses incurred by
                Fuel AI or others due to any unauthorized use of your username,
                password or account.
              </li>

              <li>
                You may not publish, reproduce, distribute, display, perform,
                edit, adapt, modify, copy, create derivative works or
                adaptations of, resell, or otherwise exploit the Services or any
                content distributed thereon under any circumstances, except as
                expressly authorized by these Terms. You may not (i) circumvent
                or disable any content protection system or digital rights
                management technology used with the Services; (ii) decompile,
                reverse engineer, disassemble or otherwise attempt to derive the
                source code of any of the technology used to deliver, or that
                forms part of, the Services; (iii) remove, alter, or conceal any
                identification, copyright, trademark, or other proprietary
                notices in or accompanying any portion of the Services or
                products or services related to the foregoing; (iv) use the
                Services to develop, preview, submit, or update any apps that
                constitute or have functionalities substantively similar to
                those of crypto-mining projects, scrapers, spyware, or malware,
                or that otherwise violate the applicable terms and policies of
                app distribution services to which Customer deploys such apps;
                or (v) access or use the Services in an unlawful or unauthorized
                manner or in a manner that is harmful to, interferes with or
                violates the security or integrity of any use of the Services,
                or the network, systems, or facilities used to provide the
                Services. Any use of the Services not expressly authorized by
                these Terms is a breach of these Terms and may violate
                copyright, trademark, or other laws.
              </li>

              <li>
                You may not: (i) disclose the identity of AI Builders and other
                Fuel AI clients or Service users; (ii) share any information or
                data about Bounties, AI Builders or other Service users with any
                third-party, including, without limitation, Bounty instructions,
                links, images, videos, audio, screenshots, screen recordings,
                and other shared documents or websites; (iii) discuss Bounties
                outside of the approved project communication channels; (iv)
                maintain any records or logs related to the Services outside of
                Bounty Systems; or (v) send any content distributed on the
                Services to your personal email address or store or save such
                content on personal devices or non-Bounty Systems.
              </li>

              <li>
                From time to time, Fuel AI may make new or updated features
                available as part of the Services (“Beta Features”). You may
                choose to try such Beta Features or not in your sole discretion.
                Beta Features are intended for evaluation purposes and not for
                production use, may not be supported, and may be subject to
                additional terms. All restrictions, Fuel AI reservations of
                rights, and your obligations concerning the Services, as
                applicable, will apply equally to your use of Beta Features.
                Fuel AI may discontinue Beta Features at any time in Fuel AI’s
                sole discretion. THE BETA FEATURES ARE PROVIDED ON AS “AS-IS”
                AND “AS AVAILABLE” BASIS. YOU ACKNOWLEDGE AND AGREE THAT ALL USE
                OF ANY BETA FEATURE IS AT YOUR SOLE RISK.
              </li>

              <li>
                Certain components of the App have been distributed or otherwise
                released or made available as “open source software” (the
                “Open-Sourced Components”). The terms of the applicable open
                source license, and not the terms of these Terms, apply to your
                access to and use of the Open-Sourced Components. For the
                avoidance of doubt, nothing in these Terms will limit any rights
                that you may have with respect to the Open-Sourced Components
                under the applicable open source license.
              </li>

              <li style={{ marginBottom: '20px' }}>
                Following completion of the Bounties you have selected, subject
                to verification that such Bounties have been accurately and
                otherwise adequately completed, you will accrue earnings in your
                account in the amount specified by the AI Builder in the Bounty
                after receiving such amount from the AI Builder and deducting
                Fuel AI’s fees as described on the Service. If the balance in
                your account exceeds $30, Fuel AI will transmit payment to you
                of your full account balance at the end of the month. If the
                balance does not meet the $30 threshold, you will not be paid
                that month and your balance will be rolled over to the following
                month. Subject to applicable law, Fuel AI reserves the right to
                delay or suspend payment during any investigation into your
                compliance with these Terms. A violation of these Terms, in Fuel
                AI’s sole determination, may result in non-payment to you of
                some or all amounts accrued, as well as suspension or
                deactivation of your account. It is your responsibility to
                ensure that you have a payment method approved by Fuel AI to
                receive payments in US Dollars or the specified currency. You
                are responsible for any and all taxes that may apply to your
                receipt of such payments, and Fuel AI may deduct and withhold
                such taxes from its payments to you if Fuel AI determines that
                it is required to do so by applicable law.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>5. Modification; Termination.</h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                Fuel AI reserves the right to permanently or temporarily modify
                or remove the Services or any portion thereof (including without
                limitation by changing the user interface of or removing certain
                features from the Services) from time to time, in Fuel AI’s sole
                discretion, without notice to you. To the fullest extent
                permitted under applicable law, Fuel AI will not be liable for
                any modification or removal of the Services or any portion
                thereof.
              </li>

              <li>
                These Terms will become effective on the date of your first
                acceptance of these Terms and continue until the earliest of:
                (i) the date upon which Fuel AI, in its sole discretion,
                provides notice to you of termination of these Terms or your
                access to the Services is terminated (which may occur for any
                reason); or (ii) your violation or breach of any provisions of
                these Terms (the “Term”). You may also discontinue your use of
                the Services at any time, for any reason. Upon expiration or
                termination of these Terms, the rights granted to you under
                these Terms with respect to the Services will immediately
                terminate. However, Fuel AI’s license to your Feedback under
                Section 12, as well as the limitations on Fuel AI’s liability
                and the indemnification provisions under Section 14, and the
                terms pertaining to the dispute resolution process under Section
                15, will survive any expiration or termination of these Terms.
              </li>

              <li style={{ marginBottom: '20px' }}>
                Upon termination of your access to the Services, or termination
                of these Terms with you, Fuel AI may at Fuel AI’s option delete
                any data associated with your account, including the
                deactivation and/or deletion of your account and any data or
                content (including Submitted Content) contained in your account.
                Without limiting the foregoing, Fuel AI may retain the any data
                that you provide to Fuel AI for such length of time as may be
                allowed by applicable law, and indefinitely in aggregated or
                de-identified form. In the event that Fuel AI suspends or
                terminates your account, Fuel AI will use reasonable efforts to
                provide you with a copy of data and content included in your
                account upon your request, Fuel AI suspended or terminated your
                account due to unlawful conduct or your failure to comply with
                any provision of these Terms.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>6. Fees and Payment; Refunds.</h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                You may be required to pay fees to Fuel AI in order to access
                and use certain functionalities, features, or resources,
                including plans and other bundles, of the Services (“Service
                Fees”). You are responsible for paying any applicable Service
                Fees listed on the Services at the time you use such
                functionality, feature, resource, plan, or bundle. All fees are
                non-refundable, to the fullest extent allowed under applicable
                law. Service Fees may be recurring. In connection with our
                on-demand options, Service Fees may be charged upon your access
                to and/or use of the on-demand functionality, feature, or
                resource. If such Service Fees are specified to be recurring on
                the Services or to be in connection with our on-demand options,
                you agree that Fuel AI may charge such Service Fees on a
                periodic basis or upon your access to the on-demand
                functionality, feature, or resource, as applicable, to the
                payment method you specify at the time of your initial purchase
                (your “Payment Method”).
              </li>

              <li>
                By using a Payment Method to pay Service Fees, you are expressly
                agreeing that Fuel AI is authorized to charge to the Payment
                Method the Service Fees and any other fees for additional
                services you may purchase, together with any applicable taxes.
                If the Payment Method is a credit card, you acknowledge that we
                may seek pre-authorization of your credit card account prior to
                your purchase to verify that the card is valid and has the
                necessary funds or credit to complete the purchase. You
                acknowledge and agree that any fees for the Services may
                increase at any time.
              </li>

              <li>
                Additional fees may apply for new features or additions to the
                Service that may be made available from time to time, in which
                case Fuel AI will provide you with notice in advance of charging
                the additional fees. In the event Fuel AI charges additional
                fees in connection with the Services, you will have an
                opportunity to review and accept the additional fees that you
                will be charged, prior to being charged. If you do not accept
                any such additional fees, Fuel AI may discontinue your access to
                the Services. You acknowledge and agree that Fuel AI may use
                third-party payment processors to process Service Fees on Fuel
                AI’s behalf, and that Fuel AI will not be held liable for any
                errors caused by such third-party payment processors.
              </li>

              <li style={{ marginBottom: '20px' }}>
                If you feel you have been charged any Service Fees in error, you
                may request a refund through your account or contact us. To
                request a refund, you may log in to your account and place your
                request through the Site. If you do not have an account, you may
                contact our Developer Success team directly at
                contact@gofuel.ai. Following your request, you will be asked to
                provide the reason for your request. We aim to respond to such
                requests within five (5) business days of our receipt of such
                requests. We note that if you file a dispute with your credit
                card company before contacting us, we are limited in what we can
                do to resolve any error. Credit card disputes are flagged to us
                by your financial services provider as potentially fraudulent so
                if a credit card dispute is initiated, your account may be
                suspended while we investigate, and we ask you to contact us if
                you wish to request that it be reinstated.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>
              7. Third-Party Services and Links.
            </h2>
            <p>
              The Services may contain links to third-party content and
              integrations with third-party platforms, such as app distribution
              services. Fuel AI does not control, endorse, sponsor, recommend,
              or otherwise accept responsibility for any loss or damage that may
              arise from your use of such third-party content and integrations.
              These links and integrations are provided only as a convenience,
              and Fuel AI does not make any representations or warranties with
              respect to third-party links and integrations. Use of any linked
              third-party content and integrations is at your own risk and
              subject to the terms of use for such third-party content and
              platforms.
            </p>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>8. Submitted Content.</h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                You remain fully responsible for your Submitted Content. You
                agree not to provide Submitted Content that, and you affirm,
                represent, and warrant that the Submitted Content that you have
                provided:
                <ul style={{ marginLeft: '40px' }}>
                  <li style={{ marginBottom: '5px' }}>
                    does not infringe, misappropriate, or otherwise violate any
                    intellectual property or other proprietary or legal rights
                    (including rights of privacy or publicity) of any third
                    party;
                  </li>

                  <li style={{ marginBottom: '5px' }}>
                    does not degrade others on the basis of gender, race, class,
                    ethnicity, national origin, religion, sexual preference,
                    disability or other classification;
                  </li>

                  <li style={{ marginBottom: '5px' }}>
                    is not unlawful, defamatory, libelous, inaccurate,
                    deceiving, or that reasonably be considered objectionable,
                    profane, obscene, sexually explicit, indecent, pornographic,
                    harassing, abusive threatening, embarrassing, hateful,
                    intended to intimidate or incite violence, or otherwise
                    inappropriate;
                  </li>

                  <li style={{ marginBottom: '5px' }}>
                    does not promote any illegal activity, or advocate, promote,
                    or assist in any unlawful act;
                  </li>

                  <li style={{ marginBottom: '5px' }}>
                    does not impersonate any person, or misrepresent your
                    identity or affiliation with any person or organization;
                  </li>

                  <li style={{ marginBottom: '5px' }}>
                    does not give the impression that it comes from or is
                    endorsed by Fuel AI or any other entity or third party, if
                    that is not the case;
                  </li>

                  <li style={{ marginBottom: '5px' }}>
                    if applicable, is a true likeness of you, the subject or
                    object and is not generated or enhanced by artificial
                    intelligence or otherwise digitally altered (e.g., using
                    filters, masks, color corrections, image combiners, or other
                    digital editing tools),.
                  </li>
                </ul>
              </li>

              <li>
                Fuel AI shall be the exclusive owner of all rights, including
                copyright, in all Submitted Content. You hereby irrevocably
                transfer, assign, and otherwise convey to Fuel AI all right,
                title, and interest in and to the Submitted Content and all
                copyrights and other intellectual property rights in the
                Submitted Content arising in any jurisdiction throughout the
                universe in perpetuity, including all registration, renewal, and
                reversion rights, and the right to sue to enforce such rights
                against infringers. Without limiting the foregoing, you give
                further permission to Fuel AI to sell, license, reproduce,
                create derivative works of, distribute, publish, make
                promotional use of, and otherwise use and commercially exploit
                the Submitted Content for any purpose (including to train and
                improve artificial intelligence models and algorithms), in all
                forms of media now known or hereafter devised (specifically
                including, without limitation, television, film, print media,
                video, and Internet), worldwide and in perpetuity, in any manner
                deemed proper by Fuel AI. You acknowledge and agree that the
                Submitted Content may be combined with other images, text and
                graphics, cropped, or otherwise combined, altered or modified in
                Fuel AI’s sole discretion, and that Fuel AI has no liability to
                you for any editing, alteration, or use of the Submitted Content
                or for any results or consequences thereof. Any credit or other
                acknowledgment of you with respect to the foregoing, if any,
                shall be determined by Fuel AI in Fuel AI 's sole discretion.
                You acknowledge and agree that you have no further right to
                additional consideration of any kind or nature with respect to
                the Submitted Content, and that Fuel AI has no obligation to
                create or use the Submitted Content or to exercise any rights
                given by these Terms.
              </li>

              <li>
                To the fullest extent permitted by applicable law, you hereby
                irrevocably waive all legal and equitable rights relating to all
                liabilities, claims, demands, actions, suits, damages, and
                expenses, including but not limited to claims for copyright or
                trademark infringement, violation of moral rights, libel,
                defamation, invasion of any rights of privacy (including
                intrusion, false light, public disclosure of private facts, and
                misappropriation of name or likeness), violation of rights of
                publicity, physical or emotional injury or distress, or any
                similar claim or cause of action in tort, contract, or any other
                legal theory, now known or hereafter known in any jurisdiction
                throughout the world (collectively, “Claims”), arising directly
                or indirectly from Fuel AI’s exercise of their rights under this
                Section 8 or the production, exhibition, exploitation,
                advertising, promotion, or other use of the Submitted Content,
                and whether resulting in whole or in part from the negligence of
                Fuel AI or any other person, and you hereby covenant not to make
                or bring any such Claim against Fuel AI and forever release and
                discharge Fuel AI from all liability in connection with such
                Claims. You hereby expressly waive all rights and benefits under
                Section 1542 of the California Civil Code, and any similar
                statute, code, law or regulation of any other jurisdiction, to
                the fullest extent that applicable law permits you to waive such
                rights and benefits. You acknowledge that you have read and
                understand Section 1542 of the California Civil Code, which
                provides that “[a] general release does not extend to claims
                that the creditor or releasing party does not know or suspect to
                exist in his or her favor at the time of executing the release
                and that, if known by him or her, would have materially affected
                his or her settlement with the debtor or released party.”
              </li>

              <li>
                AI Builders and other users may be able to access and view your
                Submitted Content on the Services, depending on your account
                settings. Once you post or share your Submitted Content with
                other users of the Services, you agree to permit such to access
                and use your Submitted Content, including viewing and forking
                your repositories, as long as that use is consistent with these
                Terms and the functionality of the Services.
              </li>

              <li style={{ marginBottom: '20px' }}>
                You understand and agree that we are not responsible for any
                Submitted Content. We are not obligated to publish or use your
                Submitted Content. We may monitor, review, edit, remove, delete,
                or disable access to your Submitted Content at any time, without
                prior notice, and in Fuel AI’s sole discretion, for any or no
                reason.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>
              9. Limitations on Use of the Services.
            </h2>
            You may not access or use, or attempt to access or use, the Services
            to take any action that could harm Fuel AI, its Providers, or any
            other third party, interfere with the operation of the Services, or
            use the Services in a manner that violates any laws. For example,
            and without limitation, you may not:
            <ul style={{ marginLeft: '40px' }}>
              <li>
                impersonate any person or entity or otherwise misrepresent your
                affiliation or the origin of any materials that form part of
                your Submitted Content, or falsify any information provided
                during registration of your account;
              </li>

              <li>
                allow any other person to use the Service under your account, or
                otherwise transfer your access to the Services to any third
                party;
              </li>

              <li>
                allow any other person to use the Service under your account, or
                otherwise transfer your access to the Services to any third
                party;
              </li>

              <li>
                engage in unauthorized “crawling,” “scraping,” or harvesting of
                content or personal information, or use any other unauthorized
                automated means to compile information available through the
                Services;
              </li>

              <li>
                use the Services as the basis for developing a competitive
                solution (or contract with a third party to do so);
              </li>

              <li>
                take any action that imposes an unreasonable or
                disproportionately large load on Fuel AI’s network,
                infrastructure, or bandwidth, including, without limitation,
                abuse of or excessively frequent requests to Fuel AI via the
                Services, as determined in Fuel AI’s sole discretion;
              </li>

              <li>
                use any device, software, or routine to interfere or attempt to
                interfere with the proper working of the Services or any
                activity conducted on the Services, including uploading or
                disseminating any virus, adware, spyware, worm, or other
                malicious code, or attempt to probe, scan, test the
                vulnerability of, or breach the security of any system or
                network;
              </li>

              <li>monitor data or traffic on the Services;</li>

              <li>
                use any device, software, or routine to interfere or attempt to
                interfere with the proper working of the Services or any
                activity conducted on the Services, including uploading or
                disseminating any virus, adware, spyware, worm, or other
                malicious code, or attempt to probe, scan, test the
                vulnerability of, or breach the security of any system or
                network;
              </li>

              <li>
                attempt to decipher, decompile, disassemble, or reverse-engineer
                any of the software comprising or in any way making up a part of
                the Services;
              </li>

              <li>
                upload, post, host, or transmit unsolicited email, SMSs, or
                "spam" messages;
              </li>

              <li>
                transmit any worms or viruses or any code of a destructive
                nature;
              </li>

              <li>
                use the Services for the purpose of exploiting, harming, or
                attempting to exploit or harm minors in any way, such as
                exposing them to inappropriate content, asking them for personal
                information, or otherwise;
              </li>

              <li>
                distribute any unauthorized materials or advertise or promote
                goods or services through the Services without Fuel AI’s
                permission (including, without limitation, by sending spam or
                any other similar solicitation);
              </li>

              <li>
                use the Services in a service bureau, outsourcing, renting,
                sublicensing, or time-sharing capacity, including to build or
                deploy apps for third parties, or otherwise rent, distribute, or
                resell the Services;
              </li>

              <li>
                engage in any other conduct that restricts or inhibits any
                person from using or enjoying the Services, or that, in Fuel
                AI’s sole judgment, exposes Fuel AI or any of Fuel AI’s users,
                affiliates, or any other third party to any liability, damages,
                or detriment of any type, including causing damage to Fuel AI’s
                reputation; or
              </li>

              <li>assist any person in doing any of the above.</li>

              <li>
                If you feel you have been charged any Service Fees in error, you
                may request a refund through your account or contact us. To
                request a refund, you may log in to your account and place your
                request through the Site. If you do not have an account, you may
                contact our Developer Success team directly at
                contact@gofuel.ai. Following your request, you will be asked to
                provide the reason for your request. We aim to respond to such
                requests within five (5) business days of our receipt of such
                requests. We note that if you file a dispute with your credit
                card company before contacting us, we are limited in what we can
                do to resolve any error. Credit card disputes are flagged to us
                by your financial services provider as potentially fraudulent so
                if a credit card dispute is initiated, your account may be
                suspended while we investigate, and we ask you to contact us if
                you wish to request that it be reinstated.
              </li>
            </ul>
            Violations of system or network security may result in civil or
            criminal liability. Fuel AI may investigate and work with law
            enforcement authorities to prosecute users who violate the Terms.
          </div>

          <div style={{ marginTop: '20px' }} className='terms-section'>
            <h2 className='terms-heading'>10. Monitoring and Enforcement.</h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                Fuel AI has the right to:
                <ul style={{ marginLeft: '20px' }}>
                  <li>
                    remove or refuse to post any Submitted Content for any or no
                    reason in Fuel AI’s sole discretion;
                  </li>

                  <li>
                    take any action with respect to any Submitted Content that
                    Fuel AI deems necessary or appropriate in Fuel AI’s sole
                    discretion, including if Fuel AI believes that such
                    Submitted Content violates these Terms, infringes any
                    intellectual property right or other right of any person or
                    entity, threatens the personal safety of users of the Site
                    or the public, or could create liability for Fuel AI;
                  </li>

                  <li>
                    disclose your identity or other information about you to any
                    third party who claims that material posted by you violates
                    their rights, including their intellectual property rights
                    or their rights of privacy or publicity;
                  </li>

                  <li>
                    take appropriate legal action, including without limitation,
                    referral to law enforcement, for any illegal or unauthorized
                    use of the Services; and
                  </li>

                  <li>
                    terminate or suspend your access to all or part of the
                    Services for any or no reason, including without limitation,
                    any violation of these Terms.
                  </li>
                </ul>
              </li>

              <li>
                Without limiting the foregoing, Fuel AI has the right to
                cooperate fully with any law enforcement authorities or court
                order requesting or directing Fuel AI to disclose the identity
                or other information of anyone posting any content on or through
                the Services.
              </li>

              <li style={{ marginBottom: '20px' }}>
                However, Fuel AI cannot review all Submitted Content as it is
                posted to the Services, and cannot ensure prompt removal of
                objectionable material after it has been posted. Accordingly,
                Fuel AI assumes no liability for any action or inaction
                regarding transmissions, communications, or content provided by
                any user or third party. We have no liability or responsibility
                to anyone for performance or nonperformance of the activities
                described in this section.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>11. Changes to Terms.</h2>
            <p>
              Fuel AI may change any of the terms contained herein at any time,
              in Fuel AI’s sole discretion. Please check these Terms
              periodically so that you are aware of any changes. Fuel AI will
              notify you by email to the address provided in your user profile
              and/or by posting a notice on the Site of any changes to these
              Terms that materially modify your rights or obligations (“Material
              Modifications”). Any Material Modifications will be effective upon
              your acceptance of the modified terms, or upon your continued use
              of the Services after Fuel AI sends or posts a notice of the
              changes, whichever is earlier. Changes to these Terms that do not
              materially modify your rights or obligations will be effective
              immediately upon publication. However, any disputes arising under
              these Terms will be resolved pursuant to the version of these
              Terms in effect at the time the dispute arose.
            </p>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>12. Feedback.</h2>
            <p>
              You hereby grant to Fuel AI an irrevocable, royalty-free,
              perpetual license to use all feedback, ideas, or suggested
              improvements you provide to Fuel AI regarding the Services (the
              “Feedback”), as applicable, including in connection with Fuel AI’s
              development, manufacturing, and marketing of the Services or other
              products or services. You further agree that Fuel AI and Providers
              may use all or part of your Feedback or any derivative thereof in
              any manner or media without any remuneration, compensation, or
              credit to you, including to improve the Services and create other
              products and services.
            </p>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>13. Communications with You.</h2>
            <p>
              Fuel AI may communicate with you about the Services, including
              through one or more third-party email or survey services, via
              methods determined by Fuel AI, including through the Services or
              contact information you provide as part of the registration
              process for the Services. You consent to receive communications
              from Fuel AI that may: (a) solicit Feedback via email, surveys,
              bug reports, or other methods Fuel AI may determine; (b) collect
              additional information regarding issues you report in your
              Feedback; (c) notify you of changes to the Services or these
              Terms; and (d) tell you about future Fuel AI programs, products,
              or services. You agree that any such notices, disclosures, and
              other communications will satisfy Fuel AI’s applicable legal
              notification requirements. Fuel AI recommends that you keep a copy
              of any electronic communications that Fuel AI sends to you for
              your records.
            </p>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>
              14. Disclaimer of Warranties, Limitation of Liability, and
              Indemnity.
            </h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN
                RISK. TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAW,
                FUEL AI MAKES NO WARRANTIES OF ANY KIND, AND THE SERVICES ARE
                PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, EXCLUSIVE OF
                ANY WARRANTY, WHETHER ORAL OR WRITTEN, EXPRESS OR IMPLIED,
                INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, OR TITLE.
                FUEL AI DISCLAIMS ALL WARRANTIES AND LIABILITIES, TO THE MAXIMUM
                EXTENT ALLOWED BY APPLICABLE LAW, REGARDING THE SECURITY,
                RELIABILITY, QUALITY, AVAILABILITY, ACCURACY, COMPLETENESS,
                COMPATIBILITY, OR UTILITY OF THE SERVICES AND PRIVACY OF YOUR
                DATA AND/OR INFORMATION. FUEL AI DOES NOT WARRANT THAT THE
                SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR FREE OF VIRUSES
                OR OTHER HARMFUL COMPONENTS, THAT DEFECTS WILL BE CORRECTED, OR
                THAT THE SERVICES ARE COMPLETELY SECURE AGAINST HACKING OR OTHER
                UNAUTHORIZED INTRUSION, OR AS TO THE ACCURACY, CURRENCY, OR
                COMPLETENESS OF ANY INFORMATION MADE AVAILABLE THROUGH THE
                SERVICES, WHETHER PROVIDED IN TEXT, AUDIO, VIDEO, GRAPHICAL, OR
                OTHER FORM (THE “SERVICE CONTENT”). FUEL AI DOES NOT MAKE ANY
                REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY THIRD-PARTY
                SERVICE PROVIDERS. FUEL AI IS NOT RESPONSIBLE OR LIABLE FOR ANY
                DECISIONS YOU MAY MAKE IN RELIANCE ON THE SERVICES OR SERVICE
                CONTENT, OR FOR ANY DEFECTS OR ERRORS THAT MAY RESULT FROM YOUR
                PROVISION OF INCOMPLETE OR INACCURATE INFORMATION TO FUEL AI OR
                THE SERVICE, YOUR USE OF THE SERVICES WITH HARDWARE THAT DOES
                NOT MEET THE MINIMUM REQUIREMENTS SPECIFIED BY FUEL AI, OR YOUR
                USE OF ANY VERSION OF THE SERVICES OTHER THAN THE MOST RECENT
                GENERALLY AVAILABLE RELEASE OF ANY OF THE SERVICES. YOU
                EXPRESSLY AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE
                TO YOUR PROPERTY, INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
                DEVICE, OR THE LOSS OF DATA, THAT RESULTS FROM USE OF THE
                SERVICES.
              </li>

              <li>
                TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, IN NO
                EVENT WILL FUEL AI OR ITS AFFILIATES, SUCCESSORS AND ASSIGNS, OR
                ANY OF THEIR RESPECTIVE INVESTORS, DIRECTORS, OFFICERS,
                EMPLOYEES, THIRD PARTY SERVICE PROVIDERS (INCLUDING THOSE WHO
                HELP ADMINISTER AND OPERATE THE SERVICES), AGENTS, AND SUPPLIERS
                (COLLECTIVELY, THE “FUEL AI PARTIES”), BE LIABLE FOR ANY DIRECT,
                INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE, SPECIAL,
                CONSEQUENTIAL, OR OTHER DAMAGES, INCLUDING, WITHOUT LIMITATION,
                DAMAGES FOR LOSS OF REVENUES, SAVINGS, PROFITS, GOODWILL, USE,
                DATA, OR OTHER INTANGIBLE LOSSES, OR THE CLAIMS OF THIRD
                PARTIES, OR ANY COST OF ANY SUBSTITUTE SERVICES, ARISING OUT OF
                OR RESULTING FROM OR IN ANY WAY RELATED TO THE SERVICES,
                REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT
                (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHER THEORY, EVEN
                IF THE FUEL AI PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. YOU FURTHER WAIVE AND HOLD HARMLESS THE FUEL AI
                PARTIES FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY SUCH
                PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY
                EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES AS REFERENCED
                IN SECTIONS 9 AND 10. TO THE FULLEST EXTENT PERMITTED BY LAW, IN
                NO EVENT SHALL THE FUEL AI PARTIES’ TOTAL LIABILITY TO YOU FOR
                ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT,
                TORT (INCLUDING NEGLIGENCE) OR OTHERWISE EXCEED THE GREATER OF
                THE ACTUAL AMOUNT YOU PAID FOR THE SERVICES (IF ANY) AND $100,
                EXCEPT TO THE EXTENT AN APPLICABLE LAW DOES NOT ALLOW
                LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                OF CERTAIN DAMAGES. THE PARTIES AGREE THAT THE FOREGOING
                LIMITATIONS ARE ESSENTIAL TO THESE TERMS.
              </li>

              <li style={{ marginBottom: '20px' }}>
                YOU FURTHER AGREE TO INDEMNIFY AND HOLD HARMLESS THE FUEL AI
                PARTIES FROM AND AGAINST ANY AND ALL LIABILITIES, CLAIMS,
                DAMAGES, EXPENSES (INCLUDING REASONABLE ATTORNEYS’ FEES AND
                COSTS), AND OTHER LOSSES ARISING OUT OF OR IN ANY WAY RELATED TO
                YOUR BREACH OR ALLEGED BREACH OF THESE TERMS, YOUR ACCESS TO,
                USE OF, OR ALLEGED USE OF THE SERVICES, YOUR FEEDBACK, YOUR
                VIOLATION OF ANY THIRD-PARTY RIGHT, INCLUDING ANY INTELLECTUAL
                PROPERTY RIGHTS, OR ANY RIGHTS OF PRIVACY OR PUBLICITY,
                CONFIDENTIALITY, OR OTHER PROPERTY OR PRIVACY RIGHT, AND ANY
                CLAIMS ARISING FROM OR ALLEGING FRAUD, INTENTIONAL MISCONDUCT,
                GROSS NEGLIGENCE, OR CRIMINAL ACTS COMMITTED BY YOU. FUEL AI
                RESERVES THE RIGHT TO EMPLOY SEPARATE COUNSEL AND ASSUME THE
                EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO
                INDEMNIFICATION BY YOU. IN SUCH EVENT, YOU SHALL PROVIDE FUEL AI
                WITH SUCH COOPERATION AS FUEL AI REASONABLY REQUESTS.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>
              15. Arbitration and Class Action Waiver.
            </h2>
            <ul style={{ marginLeft: '40px' }}>
              PLEASE REVIEW THIS SECTION, AS THIS MAY AFFECT YOUR LEGAL RIGHTS.
              THIS SECTION WILL APPLY TO THE FULL EXTENT PERMITTED BY LAW.
              <li style={{ marginLeft: '40px' }}>
                Mandatory Arbitration of Disputes. You and Fuel AI each agree
                that any dispute, claim or controversy arising out of or
                relating to these Terms or the breach, termination, enforcement,
                interpretation or validity thereof or the use of the Services
                (each, a “Dispute” and collectively, the “Disputes”) will be
                resolved solely by binding, individual arbitration, unless
                expressly provided otherwise in this Section 15, and not in a
                class, representative or consolidated action or proceeding. You
                and Fuel AI agree that the U.S. Federal Arbitration Act governs
                the interpretation and enforcement of these Terms and that YOU
                AND FUEL AI ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                PARTICIPATE IN A CLASS ACTION. This Section 15 shall survive the
                termination of these Terms.
              </li>
              <li style={{ marginLeft: '40px' }}>
                Exceptions and Opt-out Option. The only exceptions to Section 15
                are the following:
                <ul style={{ marginLeft: '40px' }}>
                  <li>
                    you or Fuel AI each may seek to resolve an individual
                    Dispute in small claims court if it qualifies.
                  </li>

                  <li>
                    you or Fuel AI each may seek injunctive or other equitable
                    relief from a court to prevent (or enjoin) the infringement
                    or misappropriation of our respective intellectual property
                    rights.
                  </li>

                  <li>
                    you may opt out of arbitration entirely and litigate any
                    Dispute individually if you provide us with a signed,
                    written notice of your decision to do so pursuant to Section
                    15(j) below; however, if you are opting out of an updated
                    version of this arbitration provision, you understand that
                    you will remain subject to the prior version of any
                    arbitration provision to which you had previously agreed.
                  </li>
                </ul>
              </li>
              <li>
                Initial Dispute Resolution and Notification. You and Fuel AI
                agree that, prior to initiating an arbitration or other legal
                proceeding, you and Fuel AI will attempt to negotiate an
                informal resolution of the Dispute. To begin this process, and
                before initiating any arbitration or legal proceeding against
                Fuel AI, you must send a Notice of Dispute (“Notice”) by
                certified mail to the attention of Fuel AI at: 238 Charles
                Street, Sunnyvale, CA 94086. For purposes of these Terms,
                initiating an arbitration means filing an arbitration demand
                (“Demand”). Your Notice to Fuel AI must contain all of the
                following information: (i) your full name, address, and the
                email address associated with your Fuel AI account; (ii) a
                detailed description of the nature and basis of the Dispute;
                (iii) a description of the relief you want, including any money
                damages you request; and (iv) your signature verifying the
                accuracy of the Notice and, if you are represented by counsel,
                authorizing Fuel AI to disclose information about you to your
                attorney. After receipt of your Notice, you and Fuel AI shall
                engage in a good-faith effort to resolve the dispute for a
                period of 60 days, which both sides may extend by written
                agreement (“Informal Dispute Resolution Period”). During the
                Informal Dispute Resolution Period, neither you nor Fuel AI may
                initiate an arbitration or other legal proceeding. If the
                Dispute is not resolved during the Informal Dispute Resolution
                Period, you may initiate an individual arbitration as provided
                below.
              </li>
              <li>
                Conducting Arbitration and Arbitration Rules. Any arbitration
                must be initiated with and conducted by National Arbitration &
                Mediation (“NAM”) pursuant to its Comprehensive Dispute
                Resolution Rules and Procedures and/or its Mass Filing
                Supplemental Dispute Resolution Rules and Procedures (together,
                the “NAM Rules”), except as modified by these Terms. The NAM
                Rules are available at www.NAMADR.org, by calling NAM at
                1-800-358-2550. In any instance where the applicable NAM Rules
                and these Terms are inconsistent, these Terms shall control. An
                arbitration Demand filed with NAM must include a certification
                signed by the filing party verifying compliance with the Initial
                Dispute Resolution and Notification requirements and other
                requirements set out in this Section 15. If NAM fails or
                declines to conduct the arbitration for any reason, we will
                mutually select a different arbitration administrator. If we
                cannot agree, a court will appoint the arbitration
                administrator. Any arbitration hearing will take place in or
                nearest to the county or municipality where you live, unless you
                and Fuel AI agree to a different location or to a virtual
                hearing. The arbitrator shall have exclusive authority to decide
                all issues relating to the interpretation, applicability,
                enforceability and scope of this arbitration agreement, except
                that only a court of competent jurisdiction as set forth in
                Section 16 of these Terms (and not an arbitrator) shall have the
                exclusive authority to resolve any claim that all or part of the
                Class Action Waiver set forth in these Terms or the Mass Filing
                procedures set forth in Section 15(e) below are unenforceable,
                unconscionable, void, or voidable.
              </li>
              <li>
                Mass Filing Procedures. YOU AND FUEL AI AGREE THAT, TO THE
                FULLEST EXTENT PERMITTED BY LAW, EACH OF US IS WAIVING THE RIGHT
                TO BRING OR PARTICIPATE IN A MASS ARBITRATION. Fuel AI’s receipt
                of one or more Notice(s) of substantially similar claims brought
                by or on behalf of 25 or more claimants (including you) within a
                60-day period (“Mass Filing”) shall be subject to the additional
                procedures set forth below. Claims included in a Mass Filing, if
                not resolved during the Informal Dispute Resolution Period, may
                proceed only in accordance with the procedures set out below,
                and subject to the NAM Mass Filing Supplemental Dispute
                Resolution Rules and Procedures (“NAM Mass Filing Rules”,
                available at
                https://www.namadr.com/resources/rules-fees-forms/”) to the
                extent not contrary to these Terms. If a court determines that
                this Section 15(e) is not enforceable as to your claim, then
                your claim may only proceed individually in court consistent
                with these Terms.
                <ul style={{ marginLeft: '40px' }}>
                  <li>
                    Batching: You and Fuel AI agree that your and other
                    individuals’ claims deemed by Fuel AI a Mass Filing may be
                    filed with NAM in batches of no greater than 50 individuals’
                    claims at one time, with 25 claims (or half of the total
                    number of claims in a batch, if less than 50) selected by
                    counsel for you and other claimants and 25 claims (or half
                    of the total number of claims in a batch, if less than 50)
                    selected by Fuel AI. After your claim is batched and
                    permitted to be filed as a Demand, you and Fuel AI agree
                    that selection and appointment of an arbitrator for your
                    Demand shall be governed by the NAM Mass Filing Rules.
                  </li>

                  <li>
                    First (Bellwether) Batch: The first batch of up to 50
                    Demands are the Bellwether Arbitrations. If your claim is
                    included in the Bellwether Arbitrations, you and Fuel AI
                    shall cooperate with the arbitrator assigned to your
                    arbitration to resolve your claim within 120 days of the
                    initial pre-hearing conference.
                  </li>

                  <li>
                    Stay of Filing of Other Claims: If your claim is not among
                    those selected for the Bellwether Arbitrations, your claim
                    cannot be filed until it is assigned to a batch and
                    authorized to be filed in a later stage of this process. No
                    arbitration fees will be assessed on you or Fuel AI in
                    connection with your claim unless and until it is assigned
                    to a batch and authorized to be filed with NAM.
                  </li>

                  <li>
                    Mediation: After the Bellwether Arbitrations are completed,
                    if your claim remains unresolved, you and Fuel AI agree to
                    mediate your claim along with any other unresolved claims
                    included in the Mass Filing (“Global Mediation”). The
                    mediator will be selected according to the procedure set
                    forth in the NAM Rules, and Fuel AI will pay the mediator’s
                    fees. The Global Mediation shall be completed within 120
                    days of the selection of a mediator, unless extended by
                    written agreement between you and Fuel AI.
                  </li>

                  <li>
                    Election To Proceed in Court: If Global Mediation is not
                    successful in resolving your claim, and 100 or more claims
                    included in the Mass Filing remain unresolved, you or Fuel
                    AI may opt out of arbitration and elect to have your claim
                    resolved in court consistent with Section 16 of these Terms.
                    You or Fuel AI must exercise this election within 45 days of
                    the completion of Global Mediation.
                  </li>

                  <li>
                    Sequential Arbitration of Remaining Batches: If neither you
                    nor Fuel AI opt out of arbitration, another batch of no
                    greater than 50 individuals’ claims will be selected from
                    the Mass Filing, with 25 claims (or half of the total number
                    of claims in a batch, if less than 50) selected by counsel
                    for claimants and 25 claims (or half of the total number of
                    claims in a batch, if less than 50) selected by Fuel AI. If
                    your claim is included in this next batch of 50 claims, your
                    claim will be filed with NAM, and you and Fuel AI shall
                    cooperate with the arbitrator assigned to your arbitration
                    to resolve your claim within 120 days of the initial
                    pre-hearing conference. The process of batching up to 50
                    individual claims at a time will continue until the parties
                    resolve all claims included in the Mass Filing. No unbatched
                    claim can proceed to be filed as a Demand until the previous
                    batch has been resolved.
                  </li>

                  <li>
                    Tolling. For any claim subject to these Mass Filing
                    procedures, any statute of limitations applicable to your
                    claim shall be tolled from the date the Informal Dispute
                    Resolution Period begins until the earlier of (1) the date
                    your arbitration Demand is filed, or (2) the date you or
                    Fuel AI opts out of arbitration pursuant to Section 15(e)
                    (“Election to Proceed in Court”).
                  </li>
                </ul>
              </li>
              <li>
                Arbitration Costs. Payment of all arbitration filing fees and
                costs will be governed by the applicable NAM Rules. If you
                prevail on your claim in arbitration, Fuel AI will reimburse you
                for any portion of the arbitration filing fees you paid that
                exceeded the amount you would have paid to file a complaint in a
                court of competent jurisdiction pursuant to Section 16. If Fuel
                AI prevails on your claim in arbitration, and the arbitrator
                finds that your claim was frivolous or filed in bad faith, the
                arbitrator may award Fuel AI reimbursement from you of Fuel AI’s
                arbitration filing fees and costs.
              </li>
              <li>
                Offer of Settlement. Fuel AI may, but is not obligated to, make
                a written offer to settle your claim at least 14 days before the
                arbitration hearing date. The amount or terms of any settlement
                offer may not be disclosed to the arbitrator until after the
                arbitrator issues an award on the claim. If an award is issued
                in your favor but is less than Fuel AI’s settlement offer, the
                arbitrator may order you to pay the arbitration costs incurred
                by Fuel AI after its offer was made, unless otherwise prohibited
                by the underlying law governing your claim.
              </li>
              <li>
                Class Action Waiver. YOU AND FUEL AI AGREE THAT, TO THE FULLEST
                EXTENT PERMITTED BY LAW, EACH OF US MAY BRING CLAIMS (WHETHER IN
                COURT OR IN ARBITRATION) AGAINST THE OTHER ONLY IN AN INDIVIDUAL
                CAPACITY, AND NOT AS A PLAINTIFF, CLAIMANT, OR CLASS MEMBER IN
                ANY PURPORTED CLASS, COLLECTIVE, CONSOLIDATED, COORDINATED,
                PRIVATE ATTORNEY GENERAL, REQUEST FOR PUBLIC INJUNCTIVE RELIEF,
                OR REPRESENTATIVE PROCEEDING. This also means that you and Fuel
                AI may not participate in any class, collective, consolidated,
                coordinated, private attorney general, request for public
                injunctive relief, or representative proceeding brought by any
                third party.  Notwithstanding this provision or any other
                language in these Terms, you or Fuel AI may participate in a
                class-wide settlement. TO THE FULLEST EXTENT PERMITTED BY LAW,
                YOU AND FUEL AI WAIVE ANY RIGHT TO A JURY TRIAL.
              </li>
              <li>
                Effect of Changes on Arbitration. Notwithstanding the provisions
                of Section 11 “Changes to Terms”, if Fuel AI changes any terms
                of this Section 15 after the date you first accepted these Terms
                or any subsequent changes to these Terms, you may reject the new
                changes to Section 15 by sending us written notice, personally
                signed by you, by certified mail to the address set out in
                Section 15(c) of these Terms within 30 days of the date such
                change became effective, as indicated by the later of (1) the “
                Last Updated” date of the Terms you seek to reject or (2) the
                date of Fuel AI’s email to you notifying you of such change.
                Even if you reject a change, you will remain subject to Section
                15 of the last version of the Terms you had accepted.
              </li>
              <li style={{ marginBottom: '20px' }}>
                Severability. If any portion of this Section 15 is found to be
                void, invalid, or otherwise unenforceable, then that portion
                shall be deemed to be severable and, if possible, superseded by
                a valid, enforceable provision, or portion thereof, that matches
                the intent of the original provision, or portion thereof, as
                closely as possible. The remainder of Section 15 and all other
                Terms shall continue to be enforceable and valid.
              </li>
            </ul>
          </div>

          <div className='terms-section'>
            <h2 className='terms-heading'>16. Miscellaneous.</h2>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                Independent Contractors. You agree that you and Fuel AI are each
                independent contractors. These Terms will not be construed as
                creating between you and Fuel AI any partnership, joint venture,
                employment relationship, agency relationship or any other
                similar relationship.
              </li>

              <li>
                Supplemental Terms. When using the Services, you agree to comply
                with and are subject to any posted guidelines, rules, or
                supplemental terms applicable to such services or features, as
                may be posted from time to time. All such guidelines, rules, or
                terms are hereby incorporated by reference into the Terms. To
                the extent that you are asked to review and accept any
                supplemental terms that expressly conflict with these Terms, the
                supplemental terms associated with the applicable portion of the
                Services govern with respect to your use of such portion of the
                Services to the extent of the conflict.
              </li>

              <li>
                Section Headers. Section headers are provided for convenience
                and do not affect the interpretation of any provision or the
                rights of any party.
              </li>

              <li>
                Entire Agreement; No Assignment. These Terms, the Privacy
                Policy, and any other terms expressly incorporated by reference
                herein form the entire agreement between you and Fuel AI
                regarding your use of the Services. These Terms, and any rights
                and licenses granted hereunder, may not be transferred or
                assigned by you, but may be assigned by Fuel AI without
                restriction. These Terms operate to the fullest extent
                permissible by law.
              </li>

              <li>
                No Joint Venture, Partnership, Employment, or Agency
                Relationship. You agree that no joint venture, partnership,
                employment, or agency relationship exists between you and Fuel
                AI as a result of these Terms or your access to and use of the
                Services.
              </li>

              <li>
                Severability. If any provision of these Terms is held to be
                unlawful, void, or unenforceable, that provision will be deemed
                severable from these Terms and will not affect the validity and
                enforceability of any remaining provisions.
              </li>

              <li>
                Force Majeure. Fuel AI will be excused from performance under
                these Terms for any period when we were prevented from or
                delayed in performing any obligations under these Terms, in who
                or in part, due to circumstances beyond Fuel AI’s reasonable
                control, including but not limited to acts of God, flood, fire,
                earthquake, explosion, war, terrorism, invasion, riot or other
                civil unrest, strikes, labor stoppages or slowdowns or other
                industrial disturbances, telecommunications, network, computer,
                server or Internet downtime, unauthorized access to Fuel AI’s
                information technology systems by third parties, or passage of
                law or any action taken by a governmental or public authority,
                including imposing an embargo.
              </li>

              <li>
                No Waiver. Fuel AI’s failure to enforce any provisions of these
                Terms or respond to a violation by any party does not waive Fuel
                AI’s right to subsequently enforce any terms or conditions of
                the Terms or respond to any violations. Nothing contained in
                these Terms is in derogation of Fuel AI’s right to comply with
                governmental, court, and law enforcement requests or
                requirements relating to your use of the Services or information
                provided to or gathered by Fuel AI with respect to such uses.
              </li>

              <li>
                Governing Law; Exclusive Jurisdiction. These Terms shall be
                governed by, and construed and interpreted in accordance with,
                the laws of the State of California (without giving effect to
                conflict of law principles). To the extent a lawsuit or court
                proceeding is permitted under these terms, you and Fuel AI agree
                that any dispute will be litigated in the state or federal
                courts located in the Northern District of California, and you
                and Fuel AI both submit to the personal and exclusive
                jurisdiction of those courts. By using the Services, you waive
                any claims that may arise under the laws of other states,
                countries, territories, or jurisdictions.
              </li>

              <li>
                Contact. For inquiries or questions regarding these Terms or the
                Services, please contact Fuel AI at the following link:
                contact@gofuel.ai. Support for the Services is available only in
                English.
              </li>

              <li>
                Export Controls. You agree that you will not use, export,
                re-export, or re-transfer the Services except as authorized by
                the laws and regulations of the United States and any other
                applicable jurisdiction. The Services may not be exported,
                re-exported, or re-transferred to (i) any jurisdiction subject
                to comprehensive economic sanctions or trade embargoes enforced
                or administered by the United States (currently, Cuba, Iran,
                North Korea, Syria, and the Crimea, Luhansk People’s Republic,
                and Donetsk People’s Republic regions of Ukraine) (collectively,
                “Sanctioned Jurisdictions”) or (ii) any person appearing on a
                restricted party list administered or enforced by the United
                States government, including, but not limited to, the United
                States Department of the Treasury’s List of Specially Designated
                Nationals and Blocked Persons and the United States Department
                of Commerce’s Entity List or Denied Persons List (collectively,
                “Restricted Parties”). By using the Services, you represent and
                warrant that you are not located, organized or resident in or a
                blocked national of a Sanctioned Jurisdiction and that you are
                not a Restricted Party.
              </li>
            </ul>
          </div>
          <p style={{marginTop: "20px"}}>
          Copyright © 2024 Fuel.Ai - GoFuel.Ai
          </p>
        </section>
      </main>
      <Footer></Footer>
    </div>

  );
};

export default Terms;
