import React from 'react';
import BountyBanner from '../components/BountyBanner';
import { Footer } from '../components/Footer';
import NetworkSection from '../components/NetworkSection';
import StepsSection from '../components/StepsSection';
import TestimonialSection from '../components/TestimonialSection';
import { customScrollToTop } from '../helpers';
import '../styles/BountyHunterPage.css';

// Simplified functional component
const SectionComponent = ({ children }) => {
  return <div>{children}</div>;
};

const BountyHunterPage = () => {
  React.useEffect(() => {
    return () => {
      customScrollToTop();
    };
  }, []);

  return (
    <div className='bounty-hunter-page'>
      <BountyBanner />
      <SectionComponent>
        <StepsSection />
      </SectionComponent>
      <SectionComponent>
        <NetworkSection />
      </SectionComponent>
      <SectionComponent>
        <TestimonialSection />
      </SectionComponent>
      <SectionComponent></SectionComponent>
      <Footer></Footer>
    </div>
  );
};

export default BountyHunterPage;
