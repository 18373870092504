import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import '../styles/AIBuilderContact.css';

const AIBuilderContact = () => {
  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');


  const handleSubmit = async event => {
    event.preventDefault(); // Prevent the default form submission behavior

    setSuccessMessage('');

    // Email validation
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    // Phone number validation
    // if (!/^\d{10}$/.test(phoneNumber)) {
    //   alert(
    //     'Please enter a valid 10-digit phone number without any dashes or spaces.'
    //   );
    //   return;
    // }

    const formData = {
      companyName,
      name,
      email,
      phoneNumber,
      message,
    };

    try {
      const docRef = await addDoc(collection(db, 'AIBuilders'), formData);
      //console.log('Document written with ID: ', docRef.id);
      // alert('Your contact information has been submitted successfully!');
      setSuccessMessage('Your contact information has been submitted successfully!')
      
      // Clear the form
      setCompanyName('');
      setName('');
      setEmail('');
      setPhoneNumber('');
      setMessage('');
    } catch (e) {
      //console.error('Error adding document: ', e);
      alert('Error submitting the form. Please try again.');
    }

    // Send AIB email

    const formattedMessage = `
    Company Name: ${formData.companyName}
    Name: ${formData.name}
    Number: ${formData.phoneNumber}
    Email: ${formData.email}
    Message:
    ${formData.message}
    `;

    // Prepare email data for sending
    const emailData = {
      to: "contact@gofuel.ai",
      subject: "New AI Builder Form Submission",
      message: formattedMessage
    };

  // Assuming sendWelcomeEmail is an HTTPS callable function
  fetch('https://us-central1-gofuel-ai.cloudfunctions.net/sendAIBEmail', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData)
  }).then(response => response.json())
  .then(data => {
      //console.log('Email sent:', data);
  }).catch(error => {
      //console.error('Failed to send email:', error);
  });
  };

  return (
    <section id='aicontact-section' className='contact-section'>
      <div className='contact-content'>
        <div className='contact-text'>
          <h1 id='contactheader'>
            Are you building an AI Automation product or solution?
          </h1>
          <p id='contacttext'>
            We've got Bounty Hunters ready to take on your project. Please fill
            out the form or feel free to email us at
            <a href='mailto:contact@gofuel.ai'> contact@gofuel.ai</a>
          </p>
        </div>
        <div className='contact-form'>
          <form onSubmit={handleSubmit}>
            <input
              type='text'
              placeholder='Company Name*'
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              required
            />
            <input
              type='text'
              placeholder='Your Name*'
              required
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              type='email'
              placeholder='Your Email*'
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <input
              type='tel'
              placeholder='Your Phone Number*'
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              required
              // pattern='^\d{10}$'
              title='Phone number must be 10 digits'
            />
            <textarea
              placeholder='Message*'
              value={message}
              onChange={e => setMessage(e.target.value)}
              required
            ></textarea>
            <button type='submit'>Submit</button>
            {successMessage && (
                <div className='success-message'>
                  {successMessage}
                </div>
              )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default AIBuilderContact;
