import { motion } from 'framer-motion';
import React from 'react';
import '../styles/SignupBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const SignupBanner = () => {
  const scrollToForm = () => {
    const section = document.getElementById('signup-section');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <motion.div
      className='signup-banner'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <div className='signup-banner-content'>
        <h1>Sign Up with us </h1>
        <p>Become a Bounty Hunter and earn more every day</p>
        <div className='signup-banner-buttons'>
          <motion.button
            className='signup-banner-button'
            onClick={scrollToForm}
            variants={buttonVariants}
            whileHover='hover'
          >
            Sign Up Below
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default SignupBanner;
