import { motion } from 'framer-motion';
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import '../styles/AboutUsBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const AboutUsBanner = () => {
  const scrollbelow = () => {
    const section = document.getElementById('mission-section');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={containerVariants}
      className='about-us-banner'
    >
      <div className='about-us-banner-content'>
        <h1>Your Personal Digital Marketplace</h1>
        <p id='aboutbannertext' className='aboutbannertext'>
          We are the best online marketplace for first-party data. AI companies find exclusive datasets here, and Bounty Hunters earn money by providing images, videos, text, and audio files.
        </p>
      </div>
      <ScrollLink to="mission-vision-section" smooth={true} duration={500} className="about-info-button">
          <div>
            More Information
            <div className="arrow"></div>
          </div>
        </ScrollLink>

    </motion.div>
  );
};

export default AboutUsBanner;
