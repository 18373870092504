import { motion } from 'framer-motion';
import React from 'react';
import firststep from '../assets/img/7.png';
import secondstep from '../assets/img/8.png';
import '../styles/StepSection.css';

// Variants for the icons
const iconVariants = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: { type: 'spring', stiffness: 260, damping: 20 },
  },
};

// Variants for the text
const textVariants = {
  hidden: { x: -100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { type: 'spring', stiffness: 260, damping: 20 },
  },
};

const StepsSection = () => {
  return (
    <div className='steps-section'>
      <motion.div className='step' initial='hidden' animate='visible'>
        <motion.img
          className='step-icon'
          src={firststep}
          alt='Capture Data'
          variants={iconVariants}
        />
        <motion.h3 variants={textVariants}>Capture Data</motion.h3>
        <motion.p variants={textVariants}>
          Snap and upload pictures, videos, and more. Document the world and
          drive AI innovation.
        </motion.p>
      </motion.div>
      <motion.div className='step' initial='hidden' animate='visible'>
        <motion.img
          className='step-icon'
          src={secondstep}
          alt='Get Paid'
          variants={iconVariants}
        />
        <motion.h3 variants={textVariants}>Get Paid</motion.h3>
        <motion.p variants={textVariants}>
          Submit your captures and watch your account grow. Your everyday
          content, valued and rewarded.
        </motion.p>
      </motion.div>
    </div>
  );
};

export default StepsSection;
