import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore'; // Import Firestore functions
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { db } from '../firebaseConfig'; // Make sure your Firebase configuration is correctly set up
import '../styles/WaitlistBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const WaitlistBanner = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async event => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Email validation
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      // Query Firestore to check if the email already exists
      const q = query(collection(db, 'waitlist'), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        alert('This email is already on the waitlist. We will inform you as soon as we launch!');
      } else {
        // Add the new email to Firestore if it doesn't already exist
        const docRef = await addDoc(collection(db, 'waitlist'), { email });
        //console.log('Document written with ID: ', docRef.id);
        alert('Thank you for subscribing!');
        setEmail(''); // Optional: Clear the email input after successful submission
      }
    } catch (e) {
      //console.error('Error adding document: ', e);
      alert('Error submitting the form. Please try again.');
    }
  };

  return (
    <motion.div className='waitlist-banner d-flex align-items-center justify-content-center'
    initial='hidden'
    animate='visible'
    variants={containerVariants}
    >
      <Container>
        <Row>
          <Col xs={12} className='text-center'>
            <h2>Our new app is on the way</h2>
            <p>Sign up to be the first to know when we launch.</p>
            <Form
              className='form-inline justify-content-center'
              onSubmit={handleSubmit}
            >
              <Form.Control
                type='email'
                placeholder='Enter Your Email'
                className='email-input'
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
              <Button variant='primary' className='notify-btn' type='submit'>
                Notify Me
              </Button>
            </Form>
            <div className='social-icons mt-4'>
              <a
                href='https://www.facebook.com/profile.php?id=61558760495697'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size='lg'
                  className='mx-2x'
                />
              </a>
              <a
                href='https://www.linkedin.com/company/fuelai/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size='lg'
                  className='mx-2x'
                />
              </a>
              <a
                href='https://www.instagram.com/GoFuel.AI'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size='lg'
                  className='mx-2x'
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default WaitlistBanner;
