import React from 'react';
import { Footer } from '../components/Footer';
import TableSection from '../components/TableSection';
import '../styles/Privacy.css';

const Privacy = () => {
  return (
    <div fluid className='privacy-container'>
      <header className='text-center'>
        {/* Placeholder for site navigation header */}
      </header>
      <main className='py-5 text-center text-light'>
        <h1 className='privacy-title'>Privacy Policy</h1>
        <p className='privacy-intro'>
          Please carefully read our Privacy Policy
        </p>
        <section className='privacy-text'>
          <div className='privacy-section'>
            <h2 className='privacy-heading'>1. Introduction.</h2>
            <p>
            Fuel AI is committed to protecting the privacy and security of the information we 
            collect and to being transparent about the ways in which we collect and process 
            your information. This statement (the “Privacy Policy”) sets forth our policies and
            practices for handling the information we collect from or about you. This Privacy 
            Policy will inform you as to how we look after your personal data when you visit 
            our website and use our services, and tell you about any privacy rights you may 
            have and how the law protects you. It applies to the website, mobile application 
            and the content and functionality provided through our website and app, 
            including any new features, services, tools or other resources we make available 
            to you and which link to this Privacy Policy (the “Services”).
            </p>
            <p>
            Fuel.AI, Inc. is the controller and responsible for your personal data (referred to 
            as Fuel AI, “we”, “us” or “our” in this Privacy Policy).
            </p>
          </div>

          <div className='privacy-section'>
            <h2 className='privacy-heading'>2. The data we collect about you</h2>
            <p>
            We may collect, use, store and transfer different kinds of personal data about 
            you when you create an account or use our Services, among them:
            <ul>
                <li style={{marginLeft: "40px"}}>
                Information about you, including name, email address, date of birth, gender;
                </li>

                <li style={{marginLeft: "40px"}}>
                Sign-In Information, including username and password;                
                </li>

                <li style={{marginLeft: "40px"}}>
                Payment Information, including your bank account details and billing
                address, or your Zelle, Google Pay, Apple Pay, PayPal, Venmo or Cash 
                App account details, to process payments to you; and                
                </li>

                <li style={{marginLeft: "40px"}}>
                Submissions, in response to a bounty request from an AI Builder 
                (known as “Bounties”), you may provide us information such as, 
                images, videos, text and audio files you submit to us, which may 
                include some personal data, such as a picture of you or personal data 
                in the background of an image (“Submissions”). Unless the Submission 
                is intended to include personal data (such as a selfie image) we take 
                steps to anonymize any other personal data to the extent possible;                
                </li>

                <li style={{marginLeft: "40px"}}>
                Usage Data, including information about how you use our website and
                Services;                
                </li>

                <li style={{marginLeft: "40px"}}>
                Technical Data, including IP address, login data, browser type and 
                version, operating system and platform, and other information about 
                the devices you use to access our website and app;                
                </li>

                <li style={{marginLeft: "40px"}}>
                Marketing and Communications Data, including your preferences 
                in receiving marketing from us; and                
                </li>

                <li style={{marginLeft: "40px"}}>
                Correspondence you send to us: We may aggregate, de-identify or otherwise take steps to anonymize the 
                information described above. Such data is not subject to this Privacy Policy.
                We do not collect any  Special Categories of Personal Data  about you (this
                includes details about your race or ethnicity, religious or philosophical beliefs, sex
                life, sexual orientation, political opinions, trade union membership, information
                about your health, and genetic and biometric data) without your explicit consent.
                Nor do we collect any information about criminal convictions and offences.
                </li>

            </ul>
            </p>
          </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>3. How we use your personal data</h2>
            <p>
            We have set out below, in a table format, a description of all the ways we plan to
            use your personal data, and which of the legal bases we rely on to do so. We 
            have also identified what our legitimate interests are where appropriate.
            </p>

            <p>
            Note that we may rely on more than one lawful basis to process your personal 
            data, depending on the specific purpose for which we are using your data. Please
            contact us if you need details about the specific legal ground we are relying on to
            process your personal data where more than one ground has been set out in the 
            table below.
            </p>
        </div>

        <TableSection />

        <div className='privacy-section'>
            <h2 className='privacy-heading'>4. Marketing</h2>
            <p>
            We strive to provide you with choices regarding certain personal data uses, 
            particularly around marketing and advertising. You will receive marketing 
            communications from us if you have requested information from us or signed up 
            for our Services and you have not opted out of receiving that marketing.
            </p>

            <p>
            We will get your express opt-in consent before we share your personal data with 
            any third party for marketing purposes. You can ask us or third parties to stop 
            sending you marketing messages at any time.
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>5. Cookies</h2>
            <p>
            We may share your personal data with the parties set out below for the purposes
            set out in the table above:
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>6. Disclosures of your personal data.</h2>
            <p>
            You can set your browser to refuse all or some browser cookies, or to alert you 
            when websites set or access cookies. If you disable or refuse cookies, please 
            note that some parts of this website may become inaccessible or not function 
            properly. For more information about the cookies we use, please see [LINK TO 
            YOUR COOKIE POLICY].
            <ul style={{marginLeft: "40px"}}>
                <li>
                Third-party Fuel AI clients, known as “AI Builders”, who post and make Bounties available on the Services, and receive your Submissions.
                </li>

                <li>
                Third-party vendors and services providers, who provide services to us for a variety of purposes.
                </li>

                <li>
                Third parties to whom we may choose to sell, transfer or merge parts of 
                our business or our assets. Alternatively, we may seek to acquire other 
                businesses or merge with them. If a change happens to our business, then
                the new owners may use your personal data in the same way as set out in 
                this Privacy Policy.
                </li>

                <li>
                We may disclose information to respond to subpoenas, court orders, legal 
                process, law enforcement requests, legal claims or government inquiries, 
                and to protect and defend the rights, interests, safety, and security of Fuel
                AI, users, or the public.
                </li>

                <li>
                We may share information for any other purposes disclosed to you at the 
                time we collect the information or pursuant to your consent.
                </li>
            </ul>
            </p>

            <p>
            We contractually require all third parties to respect the security of your personal 
            data and to treat it in accordance with the law.
            </p>

            <p>
            If you access third-party services through the Services, such as social media 
            services (including Meta) to share information about your experience with others,
            these third-party services may be able to collect information about you and they 
            may notify your connections on the third-party services about your use of the 
            Services, in accordance with their own privacy policies.  We do not control the 
            privacy practices of these third parties, and you should review their privacy 
            policies carefully.
            </p>

            <p>
            Do-Not-Track Signals and Similar Mechanisms. Some mobile and web browsers 
            transmit "do-not-track" signals. Because of differences in how web browsers 
            incorporate and activate this feature, it is not always clear whether users intend 
            for these signals to be transmitted, or whether they even are aware of them. We 
            currently do not take action in response to these signals.
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>7. International transfers</h2>
            <p>
            We store your personal data in the United States. We may transfer your data to other jurisdictions, for instance, to share Submissions with AI Builders. Whenever
            we transfer your personal data internationally, we ensure a similar degree of protection is afforded to it and ensure appropriate safeguards are implemented 
            to protect your personal data. Please contact us if you want further information on the specific mechanism used
            by us when transferring your personal data internationally. VI.Data retention, security, and integrity
            Information will be retained only for so long as reasonably necessary for the purposes set out above, in accordance with applicable laws.
            We maintain reasonable security measures to safeguard information from loss, theft, interference, misuse, unauthorized access, disclosure, alteration, or 
            destruction. We have put in place procedures to deal with any suspected 
            </p>

            <p>
            Please contact us if you want further information on the specific mechanism used by us when transferring your personal data internationally.
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>8. Data retention, security, and integrity.</h2>
            <p>
            Information will be retained only for so long as reasonably necessary for the purposes set out above, in accordance with applicable laws.
            </p>

            <p>
            We maintain reasonable security measures to safeguard information from loss, theft, interference, misuse, unauthorized access, disclosure, alteration, or 
            destruction. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach
            where we are legally required to do so.            
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>9. Data retention, security, and integrity.</h2>
            <p>
            Subject to applicable law, you have the following rights over your personal information:
            <ul style={{marginLeft: "40px"}}>
                <li>
                Right of Access – your right to be informed of how we process your personal data and request access to your personal data;
                </li>
                <li>
                Right to Rectification – your right to request that we amend or update your
                personal data where it is inaccurate or incomplete;                
                </li>
                <li>
                Right to Erasure – your right to request that we delete your personal data;                
                </li>
                <li>
                Right to Restrict – your right to request that we temporarily or permanently stop processing all or some of your personal data;                </li>
                <li>
                Right to Object – your right to object to your personal data being processed under certain circumstances, including for direct marketing purposes;                </li>
                <li>
                Right to Data Portability – your right to request a copy of the personal data you provide to your information in electronic format and the corresponding right to transmit that personal data for use elsewhere;                </li>
                <li>
                Right not to be Subject to Automated Decision-Making - the right to not be 
                subject to a decision based solely on automated decision making, 
                including profiling, where the decision would have a legal effect on you or 
                produce a similarly significant effect; and.                
                </li>
                <li>
                Right to Withdraw Consent - Where you give consent to us to process your
                personal data, you can withdraw your consent at any time, though this 
                won’t affect the lawfulness of processing that occurred before you 
                withdrew your consent.                
                </li>
            </ul>
            </p>

            <p>
            If you wish to exercise any of these rights, please contact us as described below. Please note that we may ask you to verify your identity before responding to 
            your request.
            </p>

            <p>
            You have the right to lodge a complaint at any time with your local supervisory authority.
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>10. Changes to this Privacy Policy.</h2>
            <p>
            We may modify this Privacy Policy from time to time. When we update the
            Privacy Policy, we will revise the “Effective Date” below and post the new Privacy
            Policy. We recommend that you review the Privacy Policy each time you visit the
            Services to stay informed of our privacy practices.
            </p>
        </div>

        <div className='privacy-section'>
            <h2 className='privacy-heading'>11. Contact Information.</h2>
            <p>
            If you have any questions about this Privacy Policy or our practices, please contact us via bountyhunters@gofuel.ai.
            </p>
        </div>

        <div className='privacy-section'>
            <p>Effective Date: 1st of January, 2024</p>
        </div>

        <p style={{marginTop: "20px"}}>
          Copyright © 2024 Fuel.Ai - GoFuel.Ai
        </p>

        </section>
    </main>
    <Footer></Footer>
    </div>

  );
};

export default Privacy;