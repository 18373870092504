import { Col, Container, Row } from 'react-bootstrap';
import arrowIcon from '../assets/img/arrow-icon1.svg'; // Import the new arrow icon
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import footerlogo from '../assets/img/textlogo.png';

export const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className='footer'>
      <Container>
        <Row className='footer-top align-items-center'>
          <Col size={12} md={4}>
            <a href='/'>
              <img id='footerlogo' src={footerlogo} alt='Logo' />
            </a>
          </Col>
          <Col size={12} md={4} className='text-center footer-links'>
            {/* Update href attributes below */}
            <a href='/terms' target='_blank' rel='noopener noreferrer'>Terms of Use</a>
            <a href='/privacypolicy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
            <a href='/subjectrelease' target='_blank' rel='noopener noreferrer'>Subject Release</a>
          </Col>
          <Col size={12} md={4} className='text-center text-md-end'>
            <div className='social-icon'>
              <a href='https://www.linkedin.com/company/fuelai/' target='_blank' rel='noopener noreferrer' className='rounded-icon'>
                <img src={navIcon1} alt='Icon' />
              </a>
              <a href='https://www.facebook.com/profile.php?id=61558760495697' target='_blank' rel='noopener noreferrer' className='rounded-icon'>
                <img src={navIcon2} alt='Icon' />
              </a>
              <a href='https://www.instagram.com/GoFuel.AI' target='_blank' rel='noopener noreferrer' className='rounded-icon'>
                <img src={navIcon3} alt='Icon' />
              </a>
              <a href='#' onClick={scrollToTop} className='arrow-icon' style={{ marginLeft: '10px' }}>
                <img src={arrowIcon} alt='Arrow Icon' />
              </a>
            </div>
          </Col>
        </Row>
        <Row className='footer-bottom'>
          <Col size={12} className='text-center'>
            <p>Copyright © 2024 Fuel.Ai - GoFuel.Ai</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
