import React from 'react';
import bountyImage from '../assets/img/partner.png'; // Correct path to your image
import '../styles/Partners.css'; // Ensure the CSS path is correct

const Partners = () => {
  return (
    <div
      id='partner-section'
      className='partners-section text-white text-center py-5'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {/* Image on the left */}
          <div className='col-md-6'>
            <img src={bountyImage} alt='Abstract Shape' className='img-fluid' />
          </div>
          {/* Text on the right */}
          <div className='col-md-6 text-md-left'>
            <h2>Forge the Future with Us</h2>
            <ul className='benefits'>
              <li>Diversify your revenue through data collection.</li>
              <li>Empower your network to contribute valuable data.</li>
              <li>Gain financial rewards for your collaborative efforts.</li>
            </ul>
            <p style={{ fontSize: '1.5em', color: 'inherit' }}>
              Feel free to email us at{' '}
              <a
                href='mailto:contact@gofuel.ai'
                style={{ color: '#0b9d58', textDecoration: 'underline' }}
              >
                contact@gofuel.ai
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
