import React from 'react';

const TableSection = () => {
  return (
    <div className="table-responsive">
      <table className="data-table">
        <thead>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>Lawful basis for processing including basis of legitimate interest</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To register you as a new customer</td>
            <td>
              <ul>
                <li>Personal Information</li>
                <li>Location Data</li>
                <li>Sign-in Information</li>
              </ul>
            </td>
            <td>Performance of a contract with you</td>
          </tr>
          <tr>
            <td>To process and make your Submissions available to our AI Builders & Location Data</td>
            <td>
              <ul>
                <li>Performance of a contract with you</li>
                <li>Consent (where we process your image or other special category data)</li>
              </ul>
            </td>
            <td>
            </td>
          </tr>

          <tr>
            <td>To process payments to you for Submissions</td>
            <td>
              <ul>
                <li>Personal Information</li>
                <li>Location Data</li>
                <li>Sign-in Information</li>
              </ul>
            </td>
            <td>Performance of a contract with you</td>
          </tr>

          <tr>
            <td>To manage our relationship with you which will include:
                <ul>
                    <li>
                    Notifying you about new Bounties;
                    </li>

                    <li>
                    Notifying   you   about changes to our terms or privacy policy
                    </li>
                    
                    <li>
                    Asking you to leave a review or take a survey
                    </li>
                </ul>
            </td>
            <td>
              <ul>
                <li>Location Data</li>
                <li>Sign-in Information</li>
                <li>Marketing   and Communications Data</li>
                <li>Communications you send to us</li>
              </ul>
            </td>
            <td>
                <ul>
                    <li>
                    Necessary to comply with a legal obligation
                    </li>

                    <li>
                    Necessary   for   our legitimate   interests   (to   keep our   records   updated   and   to assess   how   people   use   our Services)
                    </li>
                </ul>
            </td>
          </tr>

          <tr>
            <td>To   administer   and protect   our   business, websites   and applications   (including troubleshooting,   data analysis, testing, system maintenance,   support, reporting and hosting of data)
            </td>
            <td>
              <ul>
                <li>Personal Information</li>
                <li>Technical Data</li>
              </ul>
            </td>
            <td>
                <ul>
                    <li>
                    Necessary   for   our legitimate   interests   (for running   our   business, provision   of   administration and   IT   services,   network security, to prevent fraud and in   the   context   of   a   business reorganisation   or   group restructuring exercise)
                    </li>

                    <li>
                    Necessary to comply with a legal obligation                    
                    </li>
                </ul>
            </td>
          </tr>

          <tr>
            <td>To use data analytics to improve our Services
            </td>
            <td>
              <ul>
                <li>Personal Information</li>
                <li>Usage Data</li>
              </ul>
            </td>
            <td>
                <ul>
                    <li>
                    Necessary   for   our legitimate   interests   (to   keep our   Services   updated   and relevant,   and   to   develop   our business)                    
                    </li>

                    <li>
                    Consent   (where   we   use cookies   or   other   tracking technologies to collect data)                    
                    </li>
                </ul>
            </td>
          </tr>

          <tr>
            <td>To make suggestions and recommendations to you about Bounties that may be of interest to you
            </td>
            <td>
              <ul>
                <li>Personal Information</li>
                <li>Technical Data</li>
                <li>Usage Data</li>
                <li>Marketing   and Communications</li>
              </ul>
            </td>
            <td>
            Necessary   for   our   legitimate interests   (to   develop   our Services   and   grow   our business)
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  );
};

export default TableSection;
