import React, { useEffect } from 'react';
import AIBuilderBanner from '../components/AIBuilderBanner'; // Import the BountyHunterBanner component
import AIBuilderContact from '../components/AIBuilderContact';
import DataCollect from '../components/DataCollect';
import DataReceive from '../components/DataReceive';
import DataRequest from '../components/DataRequest';
import { Footer } from '../components/Footer';
import { customScrollToTop } from '../helpers';
import '../styles/AIBuilderPage.css'; // Ensure this path is correct

const AIBuilderPage = () => {
  useEffect(() => {
    return () => {
      customScrollToTop();
    };
  }, []);
  return (
    <div className='ai-builder-page'>
      <AIBuilderBanner />
      <DataRequest />
      <DataCollect />
      <DataReceive />
      <AIBuilderContact />
      <Footer></Footer>
    </div>
  );
};

export default AIBuilderPage;
